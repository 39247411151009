import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'
import { useURLTitle } from 'hooks/common/useURLTitle'
import { useDelayedContinue } from 'hooks/ui/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { PAGE_NAME, QUESTIONS } from 'pages/intro/constants'

import { DynamicDiscountBanner } from 'components/DynamicDiscountBanner'
import { Option, OptionType } from 'components/Option'

import appImg from 'assets/images/intro-5.png'

import { Confidence } from 'root-constants/common'

import {
  LavenderBackgroundGlobalStyles,
  StyledIntroVariant5 as S,
} from './IntroVariant5.styles'

export const IntroVariant5: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const urlTitle = useURLTitle()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: urlTitle || QUESTIONS[pageId],
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <LavenderBackgroundGlobalStyles />
      <S.BurgerMenu />
      <DynamicDiscountBanner />

      <S.ImageContainer>
        <img src={appImg} alt="map" />
      </S.ImageContainer>

      <S.Column>
        <S.Title>{urlTitle || t`onboarding.intro.questionSpouse`}</S.Title>
        <S.OptionsContainer>
          <Option
            {...optionProps}
            value={Confidence.YES}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>{t`commonComponents.yes`}</S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={Confidence.NO}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>{t`commonComponents.no`}</S.QuestionButton>
          </Option>
        </S.OptionsContainer>
        <Option
          {...optionProps}
          value={Confidence.NOT_SURE}
          disabled={isAnswersDisabled}
        >
          <S.NotSureButton>{t`commonComponents.notSure`}</S.NotSureButton>
        </Option>
        <S.PoliciesGroup />
      </S.Column>
    </S.Wrapper>
  )
}
