import styled from 'styled-components'

import { MediaBreakpoint } from 'root-constants/common'

type TProps = {
  marginBottom?: number
  height?: number
}
export const StyledStepsVariant1 = {
  StepsWrapper: styled.div<TProps>`
    display: flex;
    gap: 24px;
    margin-top: 24px;
    margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;

    img {
      max-height: ${({ height }) => height || 570}px;
      margin-top: 9px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-top: 48px;

      img {
        max-height: 530px;
      }
    }
  `,
  StepsContent: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 269px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 830px;
      gap: 48px;
    }
  `,
  StepsTitle: styled.h4`
    padding: 4px 12px;
    border-radius: 16px;
    margin-bottom: 12px;
    background-color: #eff5f7;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 24px;
    }
  `,
  StepsSubtitle: styled.p`
    color: #5c90ff;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 4px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 8px;
    }
  `,
  StepsText: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 18px;
      line-height: 25px;
    }
  `,
}
