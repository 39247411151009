import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { usePageInfo } from 'providers/PageInfoProvider'

import {
  selectCurrentVariantCohortToUse,
  selectCurrentVariantSteps,
} from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { useChipoloUpgradeStatus } from 'hooks/user/useChipoloUpgradeStatus'
import { useUpsellStatus } from 'hooks/user/useUpsellStatus'

import { EMAIL_COHORTS } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

export const useUserStatus = (): string => {
  const steps = useSelector(selectCurrentVariantSteps)
  const userStatus = useSelector(selectUserStatus)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const { currentSubscriptionPageId, currentUpsellPageId } = usePageInfo()
  const isChipoAvailable = useChipoloUpgradeStatus()
  const isUpsellAvailable = useUpsellStatus()

  const isEmailCohort = EMAIL_COHORTS.includes(cohortToUse)

  return useMemo(() => {
    if (isEmailCohort && !userStatus?.email.hasEmail) {
      return PageId.PROXY_TO_START
    }

    if (userStatus?.account.hasAccount) {
      return PageId.DOWNLOAD
    }

    if (isUpsellAvailable) {
      return currentUpsellPageId
    }

    if (isChipoAvailable) {
      return PageId.CHIPOLO_UPGRADE_1
    }

    if (userStatus?.hasSubscription) {
      return PageId.ACCOUNT
    }

    if (userStatus?.email.hasEmail) {
      return currentSubscriptionPageId || steps[0].id
    }

    return ''
  }, [
    currentSubscriptionPageId,
    currentUpsellPageId,
    isChipoAvailable,
    isEmailCohort,
    isUpsellAvailable,
    steps,
    userStatus?.account.hasAccount,
    userStatus?.email.hasEmail,
    userStatus?.hasSubscription,
  ])
}
