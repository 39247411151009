import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'

import { numberToWords } from 'helpers/numberToWords'

import af from 'assets/i18n/af.json'
import ar from 'assets/i18n/ar.json'
import de from 'assets/i18n/de.json'
import en from 'assets/i18n/en.json'
import es from 'assets/i18n/es.json'
import fr from 'assets/i18n/fr.json'
import it from 'assets/i18n/it.json'
import pt from 'assets/i18n/pt.json'
import zh from 'assets/i18n/zh.json'

i18n.use(initReactI18next).init({
  resources: {
    af,
    en,
    es,
    de,
    fr,
    pt,
    it,
    zh,
    ar,
  },
  defaultNS: 'common',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

i18n.services.formatter?.addCached(
  'numberToWords',
  () => (val) => numberToWords(val),
)
