import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import sadGirlImg from 'assets/images/proof-1-pink.webp'

import { StyledSocialProofVariant22 as S } from './SocialProofVariant22.styles'

export const SocialProofVariant22: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Content>
        <S.ImageContainer>
          <img src={sadGirlImg} alt="sad girl" />
        </S.ImageContainer>
        <S.Column>
          <S.Title>
            <Trans i18nKey="onboarding.socialProof.variant1.title" />
          </S.Title>
          <S.Text>
            <Trans i18nKey="onboarding.socialProof.variant1.paragraph" />
          </S.Text>
          <S.Note>{t`onboarding.socialProof.note`}</S.Note>
          <Button onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </Button>
        </S.Column>
      </S.Content>
    </S.Wrapper>
  )
}
