import React from 'react'

import { useDynamicPage } from 'hooks/common/useDynamicPage'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { FixedContainer } from 'components/FixedContainer'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { CHECKBOX_CUSTOM_VALUES } from 'root-constants/common'

import { StyledDynamicLeftToRight as S } from './DynamicLeftToRight.styles'

export const DynamicLeftToRight: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const {
    title,
    subtitle,
    buttonText,
    optionType,
    optionData,
    optionProps,
    answers,
    isAnswersDisabled,
    isCustomChoice,
    allOptionValues,
    handleContinue,
  } = useDynamicPage(pageId, nextPagePath)

  return (
    <S.Wrapper>
      <S.Column paddingBottom={optionType === OptionType.CHECKBOX ? 100 : 0}>
        {!!title && (
          <S.Title marginBottom={subtitle ? 12 : 38}>{title}</S.Title>
        )}
        {!!subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
        {optionType === OptionType.RADIO && (
          <StyledOption.OptionsContainer key={pageId}>
            {optionData?.map(({ value, text, id }) => (
              <Option
                {...optionProps}
                key={id}
                value={value}
                disabled={isAnswersDisabled}
              >
                <QuestionButton>{text}</QuestionButton>
              </Option>
            ))}
          </StyledOption.OptionsContainer>
        )}

        {optionType === OptionType.CHECKBOX && (
          <StyledOption.OptionsContainer paddingBottom={40} key={pageId}>
            {optionData?.map(({ id, value, text }) => (
              <Option
                {...optionProps}
                key={id}
                value={value}
                checked={
                  isCustomChoice && CHECKBOX_CUSTOM_VALUES.includes(value)
                    ? answers.length === allOptionValues.length
                    : answers.includes(value)
                }
              >
                <QuestionButton hasCheckboxIcon>{text}</QuestionButton>
              </Option>
            ))}
          </StyledOption.OptionsContainer>
        )}

        {optionType === OptionType.CHECKBOX && (
          <FixedContainer>
            <Button
              type="button"
              onClick={() => handleContinue(answers)}
              disabled={!answers.length}
            >
              {buttonText}
            </Button>
          </FixedContainer>
        )}
      </S.Column>
    </S.Wrapper>
  )
}
