import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useVatInfo } from 'hooks/common/useVatInfo'

import { getPriceWithoutTaxes } from 'helpers/getPriceWithoutTaxes'

import { ISelectPlanItemProps } from 'models/common.model'

import { PriceValue } from 'components/PriceValue'

import {
  CURRENCY_SYMBOLS,
  INTRO_OFFER_PERIODS,
  INTRO_OFFER_PERIOD_NAMES,
  ProductKey,
} from 'root-constants/common'

import { StyledTrialPlanItemVariant3 as S } from './TrialPlanItemVariant3.styles'

export const TrialPlanItemVariant3: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    product,
    mainPrices: { fullPrice },
    trialPrices: { fullPrice: trialPrice, durationDays, daily, oldPrices },
  },
  isSelected,
}) => {
  const { t } = useTranslation()

  const { currency, taxAmount } = usePurchaseStore()
  const hasIncludedVat = useVatInfo()

  const oldPrice = useMemo(() => {
    const actualOldPrice =
      product === ProductKey.ONE_WEEK
        ? oldPrices.beforeCustomDiscount.fullPrice
        : fullPrice

    const actualOldPriceWithoutTaxes = getPriceWithoutTaxes({
      price: actualOldPrice,
      taxAmount,
    })

    return hasIncludedVat
      ? actualOldPriceWithoutTaxes
      : actualOldPrice.toFixed(2)
  }, [
    product,
    oldPrices.beforeCustomDiscount.fullPrice,
    fullPrice,
    taxAmount,
    hasIncludedVat,
  ])

  const priceWithoutTaxes = getPriceWithoutTaxes({
    price: trialPrice,
    taxAmount,
  })

  const dailyPriceWithoutTaxes = getPriceWithoutTaxes({
    price: daily,
    taxAmount,
  })

  return (
    <S.PlanItem
      data-is-selected={isSelected}
      data-is-default={isDefault}
      data-text={t`subscriptions.planBlock.mostPopular`}
    >
      <S.PlanTitleWrapper>
        <S.PlanTitle>
          <Trans
            i18nKey="subscriptions.planBlock.planTitle2"
            values={{
              periodQuantity: INTRO_OFFER_PERIODS[durationDays],
              periodName: t(INTRO_OFFER_PERIOD_NAMES[durationDays], {
                count: INTRO_OFFER_PERIODS[durationDays],
              }),
            }}
          />
        </S.PlanTitle>
        <S.PlanPricesWrapper>
          {!!oldPrices.beforeCustomDiscount.fullPrice && (
            <S.OldPlanPrice>
              <PriceValue value={oldPrice} currency={currency} />
            </S.OldPlanPrice>
          )}
          <S.CurrentPrice>
            <PriceValue
              value={hasIncludedVat ? priceWithoutTaxes : trialPrice}
              currency={currency}
            />
          </S.CurrentPrice>
        </S.PlanPricesWrapper>
      </S.PlanTitleWrapper>
      <S.PlanPrice data-is-selected={isSelected}>
        <strong>
          {CURRENCY_SYMBOLS[currency]}
          {hasIncludedVat ? dailyPriceWithoutTaxes : daily}
        </strong>
        <span>
          {t('subscriptions.planBlock.perPeriod', {
            interval: t('subscriptions.planBlock.perDay'),
          })}
        </span>
      </S.PlanPrice>
    </S.PlanItem>
  )
}
