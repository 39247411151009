import React, { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useVatInfo } from 'hooks/common/useVatInfo'

import { SupportLink } from 'components/SupportLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { CURRENCY_SYMBOLS } from 'root-constants/common'

import { PERIOD_QUANTITY_TO_MARKUP_MAP } from '../../../constants'
import { StyledSubscriptionDescription } from './SubscriptionDescription.styles'

type TSubscriptionDescriptionProps = HTMLAttributes<HTMLParagraphElement>

export const SubscriptionDescription: React.FC<
  TSubscriptionDescriptionProps
> = ({ ...props }) => {
  const { currentPrice, periodQuantity, currency } = usePurchaseStore()
  const hasIncludedVAT = useVatInfo()

  const { t } = useTranslation()

  const currentPriceFormatted = +currentPrice.toFixed(2)

  return (
    <StyledSubscriptionDescription {...props}>
      {t('subscriptions.description.text', {
        price: currentPriceFormatted,
        currencySymbol: CURRENCY_SYMBOLS[currency],
        period: t(PERIOD_QUANTITY_TO_MARKUP_MAP[periodQuantity as number]),
        vatInfo: hasIncludedVAT ? t('commonComponents.inclVat') : '',
      })}{' '}
      <TermsOfUseLink /> {t`subscriptions.description.contact`} <SupportLink />
    </StyledSubscriptionDescription>
  )
}
