import styled, { css } from 'styled-components'

import { StripePaymentForm } from 'modules/payment/components/StripePaymentForm'

import { Color, MediaBreakpoint } from 'root-constants/common'

type TTextProps = {
  gridArea: string
}

const commonPriceTextStyles = css`
  color: ${Color.BLUE};
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`

export const StyledPaymentFlowsGoogleVariant2 = {
  Wrapper: styled.div``,
  PriceContainer: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'priceText priceValue'
      'trialDuration trialDuration';
    margin: 24px 0 16px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin: 32px 0 24px;
    }
  `,
  PriceText: styled.p<TTextProps>`
    ${commonPriceTextStyles};
    grid-area: ${({ gridArea }) => gridArea};
    justify-self: flex-start;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 40px;
      line-height: 48px;
    }
  `,
  PriceValue: styled.p<TTextProps>`
    ${commonPriceTextStyles};
    grid-area: ${({ gridArea }) => gridArea};
    justify-self: flex-end;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 40px;
      line-height: 48px;
    }
  `,
  TrialDurationText: styled.p`
    grid-area: trialDuration;
    justify-self: flex-end;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 16px;
      line-height: 20px;
    }
  `,
  Disclaimer: styled.p`
    margin-bottom: 16px;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.LIGHT_TEXT};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 12px;
      margin-bottom: 24px;
    }
  `,
  StripePaymentForm: styled(StripePaymentForm)`
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: initial;
      margin-bottom: 25px;
    }
  `,
  Image: styled.img`
    max-width: 256px;
    margin: 40px auto 16px;
  `,
  Guarantee: styled.p`
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    opacity: 0.7;
    margin-bottom: 48px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 40px;
    }
  `,
  BottomDisclaimer: styled.p`
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};
    margin-bottom: 44px;

    a {
      color: inherit;
      text-decoration: underline;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 12px;
    }
  `,
}
