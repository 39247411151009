import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { DesktopProvider } from 'components/DesktopProvider'

import kidImg from 'assets/images/proof-kid.webp'
import socialProofImageDesktop from 'assets/images/social-proof-4.jpg'

import { StyledSocialProof as S } from './SocialProofVariant4.styles'
import { PAGE_NAMES } from './constants'

export const SocialProofVariant4: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAMES[pageId],
    question: '',
    nextPagePath,
  })

  return (
    <DesktopProvider imageSrc={socialProofImageDesktop}>
      <S.Wrapper>
        <S.ImageContainer>
          <img src={kidImg} alt="kid" />
        </S.ImageContainer>
        <S.Column>
          <S.Text marginBottom={24} marginBottomDesktop={82}>
            <Trans i18nKey="onboarding.socialProof.variant4.paragraph1" />
          </S.Text>
          <S.Text marginBottom={16} marginBottomDesktop={54}>
            <Trans i18nKey="onboarding.socialProof.variant4.paragraph2" />
          </S.Text>
          <S.Note>
            <Trans i18nKey="onboarding.socialProof.variant4.note" />
          </S.Note>
          <S.Button type="button" onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </S.Button>
        </S.Column>
      </S.Wrapper>
    </DesktopProvider>
  )
}
