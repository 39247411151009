import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { StyledBenefitsVariant3 as S } from './BenefitsVariant3.styles'
import { APP_BENEFITS } from './constants'

type TProps = {
  benefits?: {
    subtitle: string
    image: string
  }[]
  textAlign?: string
}
export const BenefitsVariant3: React.FC<TProps> = ({
  benefits = APP_BENEFITS,
  textAlign,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  return (
    <S.Wrapper id="2">
      <S.Title>{t`subscriptions.subscriptionsGoogle.variant1.appBenefits.title`}</S.Title>
      <S.ContentWrapper>
        {benefits.map(({ subtitle, image }) => (
          <S.Content key={subtitle}>
            <S.Subtitle textAlign={textAlign}>
              <Trans i18nKey={subtitle} />
            </S.Subtitle>
            <S.Image src={`${image}_${language}.png`} alt="benefits" />
          </S.Content>
        ))}
      </S.ContentWrapper>
    </S.Wrapper>
  )
}
