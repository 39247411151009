import React from 'react'
import { useSelector } from 'react-redux'

import { usePageInfo } from 'providers/PageInfoProvider'
import { useTheme } from 'styled-components'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { getIsMobileDevice } from 'helpers/getIsMobileDevice'

import { Cohort } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { ProgressBar } from '../ProgressBar'
import { StyledHeader as S } from './Header.styles'

type TProps = {
  headerImg?: string
  headerBorder?: string
}
export const Header: React.FC<TProps> = ({ headerImg, headerBorder }) => {
  const theme = useTheme()

  const cohort = useSelector(selectCurrentVariantCohortToUse)

  const isMobile = getIsMobileDevice()
  const { hasHeader, currentPageId } = usePageInfo()

  const hasDifferentHeaderPosition =
    cohort === Cohort.MAIN_1 || cohort === Cohort.SPOUSE_1

  const hasNoDesktopHeader =
    (currentPageId === PageId.ACCOUNT || currentPageId === PageId.DOWNLOAD) &&
    !isMobile &&
    !hasDifferentHeaderPosition

  if (
    !hasHeader ||
    (hasDifferentHeaderPosition && !isMobile) ||
    hasNoDesktopHeader
  ) {
    return null
  }

  return (
    <S.HeaderWrapper headerBorder={headerBorder}>
      <S.Header>
        <img
          height={theme.dimensions.headerLogoHeight}
          src={headerImg || theme.images.headerLogo}
          alt="logo"
        />
      </S.Header>
      <ProgressBar />
    </S.HeaderWrapper>
  )
}
