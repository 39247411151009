import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'
import { useDelayedContinue } from 'hooks/ui/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { Language } from 'root-constants/common'

import { StyledChildSchoolIssues as S } from './ChildSchoolIssues.styles'
import { OPTION_VALUES, PAGE_NAME } from './constants'

export const ChildSchoolIssuesVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.childSchoolIssues.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.childSchoolIssues.title`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.GOING_WELL}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.childSchoolIssues.goingWell`}</QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.SOME_PROBLEMS}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.childSchoolIssues.someProblems`}</QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.NOT_SURE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.childSchoolIssues.notSure`}</QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.DEFINITELY_PROBLEMATIC}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.childSchoolIssues.definitelyProblematic`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
