import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { ReviewsSlider2 } from 'components/ReviewsSlider'
import { REVIEWS_DATA_SLIDER_VARIANT_3 } from 'components/ReviewsSlider/constants'
import { Security } from 'components/Security'

import firstBenefitImage from 'assets/images/intro-google2-first-benefit.webp'
import secondBenefitImage from 'assets/images/intro-google2-second-benefit.webp'
import socialProofImage from 'assets/images/intro-google2-social-proof.png'
import thirdBenefitImage from 'assets/images/intro-google2-third-benefit.webp'
import relievedEmoji from 'assets/images/relieved-emoji.png'

import { StyledIntroGoogleVariant2 as S } from './IntroGoogleVariant2.styles'
import { PAGE_NAME } from './constants'

export const IntroGoogleVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.BurgerMenu />
      <S.Column>
        <S.Title>
          <Trans i18nKey="onboarding.introGoogle2.title" />
        </S.Title>
        <S.SocialProofImageContainer>
          <img src={socialProofImage} alt="social-proof" />
        </S.SocialProofImageContainer>
      </S.Column>

      <S.Card>
        <S.TitleContainer>
          <S.CardTitle>
            <Trans i18nKey="onboarding.introGoogle1.benefits.first.title" />
          </S.CardTitle>
          <S.CardSubtitle>{t`onboarding.introGoogle1.benefits.first.subtitle`}</S.CardSubtitle>
        </S.TitleContainer>
        <S.CardImage src={firstBenefitImage} alt="family" />
        <Button type="button" onClick={() => handleContinue('')}>
          {t`actions.tryNow`}
        </Button>
      </S.Card>

      <S.SocialProofContainer>
        <S.SocialProofTitle>
          <Trans i18nKey="onboarding.introGoogle2.socialProofTitle" />
        </S.SocialProofTitle>
        <S.SocialProofText>
          <Trans
            i18nKey="onboarding.introGoogle2.socialProofText"
            components={{
              emoji: <S.Emoji src={relievedEmoji} alt="emoji" />,
              strong: <strong />,
            }}
          />
        </S.SocialProofText>
      </S.SocialProofContainer>

      <S.Card>
        <S.TitleContainer>
          <S.CardTitle>{t`onboarding.introGoogle1.benefits.second.title`}</S.CardTitle>
          <S.CardSubtitle>{t`onboarding.introGoogle1.benefits.second.subtitle`}</S.CardSubtitle>
        </S.TitleContainer>
        <S.CardImage src={secondBenefitImage} alt="mom" />
        <Button type="button" onClick={() => handleContinue('')}>
          {t`actions.tryNow`}
        </Button>
      </S.Card>

      <ReviewsSlider2
        reviews={REVIEWS_DATA_SLIDER_VARIANT_3}
        marginBottom={40}
      />

      <S.Card>
        <S.TitleContainer>
          <S.CardTitle>{t`onboarding.introGoogle1.benefits.third.title`}</S.CardTitle>
          <S.CardSubtitle>{t`onboarding.introGoogle1.benefits.third.subtitle`}</S.CardSubtitle>
        </S.TitleContainer>
        <S.CardImage src={thirdBenefitImage} alt="woman" />
        <Button type="button" onClick={() => handleContinue('')}>
          {t`actions.tryNow`}
        </Button>
      </S.Card>

      <S.Column>
        <Security />
      </S.Column>
    </S.Wrapper>
  )
}
