import React, { InputHTMLAttributes, useRef, useState } from 'react'

import { useClickOutside } from 'hooks/ui/useClickOutside'

import { StyledInputWithFloatPlaceholder as S } from './InputWithFloatPlaceholder.styles'

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  labelName: string
  isValid?: boolean
  validationText?: string
  hintText?: string
  marginBottom?: number
}

export const InputWithFloatPlaceholder: React.FC<TProps> = React.forwardRef<
  HTMLDivElement,
  TProps
>(
  (
    {
      value,
      isValid = true,
      labelName,
      validationText = '',
      hintText = '',
      marginBottom = 24,
      ...props
    },
    ref,
  ) => {
    const [isHintVisible, setIsHintVisible] = useState<boolean>(false)
    const hintRef = useRef<HTMLDivElement>(null)
    const hintButtonRef = useRef<HTMLButtonElement>(null)

    useClickOutside({
      ref: hintRef,
      callback: () => setIsHintVisible(false),
      excludedRef: hintButtonRef,
    })

    return (
      <S.Wrapper ref={ref} marginBottom={marginBottom}>
        <S.Input
          value={value}
          isValid={isValid}
          hasValue={!!value}
          {...props}
        />
        <S.Label>{labelName}</S.Label>

        {hintText && (
          <S.HintButton
            ref={hintButtonRef}
            type="button"
            onClick={() => setIsHintVisible((prevValue) => !prevValue)}
          />
        )}
        {isHintVisible && hintText && <S.Hint ref={hintRef}>{hintText}</S.Hint>}

        {!isValid && validationText && (
          <S.ValidationText>{validationText}</S.ValidationText>
        )}
      </S.Wrapper>
    )
  },
)
