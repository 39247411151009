import styled from 'styled-components'

import { QuestionButton } from 'components/QuestionButton'

import {
  baseAdaptiveColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledPartnersDataVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    min-height: 100dvh;
  `,
  Column: styled.div`
    ${baseAdaptiveColumnStyles};
    padding: 24px 25px 120px;
  `,
  Content: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    background-color: #f5f5ff;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 16px;
  `,
  QuestionButton: styled(QuestionButton)`
    background-color: ${Color.WHITE};

    &[data-has-check-icon='true'] {
      border-radius: 12px;
      border: 1px solid transparent;
    }

    input:checked + & {
      &[data-has-check-icon='true'] {
        background-color: #e7e6ff;
        border: 1px solid ${Color.ACCENT_VIOLET};
      }
    }
  `,
}
