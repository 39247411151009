import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { FixedContainer } from 'components/FixedContainer'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import {
  CheckboxCustomValue,
  Language,
  TrackingData,
} from 'root-constants/common'

import { StyledData as S } from './Data.styles'
import { PAGE_OPTION_VALUES } from './constants'

export const DataVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    pageName: '',
    question: t('onboarding.data.question', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked) {
        value === CheckboxCustomValue.ALL_OF_THE_ABOVE
          ? setAnswers(PAGE_OPTION_VALUES[pageId])
          : setAnswers([...answers, value])
        return
      }

      value === CheckboxCustomValue.ALL_OF_THE_ABOVE
        ? setAnswers([])
        : setAnswers(answers.filter((item) => item !== value))
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.data.question`}</S.Title>
        <StyledOption.OptionsContainer paddingBottom={4}>
          <Option
            {...optionProps}
            value={TrackingData.SOS_NOTIFICATIONS}
            checked={answers.includes(TrackingData.SOS_NOTIFICATIONS)}
          >
            <QuestionButton hasCheckboxIcon>
              {t`onboarding.data.answers.sosNotifications`}
            </QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={TrackingData.BATTERY_ALERTS}
            checked={answers.includes(TrackingData.BATTERY_ALERTS)}
          >
            <QuestionButton hasCheckboxIcon>
              {t`onboarding.data.answers.batteryAlerts`}
            </QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={TrackingData.DRIVING_MODE}
            checked={answers.includes(TrackingData.DRIVING_MODE)}
          >
            <QuestionButton hasCheckboxIcon>
              {t`onboarding.data.answers.drivingMode`}
            </QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={TrackingData.GET_HURTS}
            checked={answers.includes(TrackingData.GET_HURTS)}
          >
            <QuestionButton hasCheckboxIcon>
              {t`onboarding.data.answers.getHurts`}
            </QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={CheckboxCustomValue.ALL_OF_THE_ABOVE}
            checked={answers.length === PAGE_OPTION_VALUES[pageId].length}
          >
            <QuestionButton hasCheckboxIcon>
              {t`commonComponents.allOfTheAbove`}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <FixedContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
          >
            {t`actions.next`}
          </Button>
        </FixedContainer>
      </S.Column>
    </S.Wrapper>
  )
}
