import styled from 'styled-components'

import { PhoneForm } from 'modules/subscriptions/components/google/PhoneForm'

import { BurgerMenu } from 'components/BurgerMenu'
import { Button } from 'components/Button'

import bgImg from 'assets/images/intro-google5-bg.webp'
import desktopBgImg from 'assets/images/intro-google5-desktop-bg.webp'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

type TTextProps = {
  hasOverSizeFont: boolean
}

export const StyledIntroGoogleVariant5 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: calc(100dvh - ${HEADER_HEIGHT}px);
    padding-top: 52px;
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 14px;
      right: 6px;
      background-size: 24px;
      width: 24px;
      height: 24px;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        top: 3px;
        right: 3px;
      }
    }

    nav {
      button {
        top: 16px;
        left: 24px;
        width: 40px;
        height: 40px;
        background-size: 40px;
      }
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: none;
    }
  `,
  LinksContainer: styled.div`
    position: absolute;
    inset-inline-end: 0;
    top: 0;
    right: 180px;
    z-index: 1;
    display: none;
    align-items: center;
    max-width: fit-content;
    width: 100%;
    min-height: 52px;

    nav {
      display: flex;
      align-items: center;
      gap: 24px;
      width: 100%;
    }

    a {
      font-size: 15px;
      line-height: 20px;
      text-decoration: none;
      font-weight: 600;
      color: ${Color.WHITE};
      cursor: pointer;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: flex;
    }
  `,
  Content: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    min-height: calc(100dvh - 52px);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-image: url(${bgImg});
    background-size: cover;
    background-repeat: no-repeat;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 100%;
      background-image: url(${desktopBgImg});
    }
  `,
  PhoneFormColumn: styled.div`
    ${baseColumnStyles};
    max-width: 327px;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 40px 180px 0 180px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    max-width: 327px;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 100%;
    }
  `,
  BottomFormColumn: styled.div`
    ${baseColumnStyles};
    max-width: 327px;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 100px 0;
      gap: 100px;
    }
  `,
  Title: styled.h1`
    font-size: 28px;
    font-weight: 800;
    line-height: 38px;
    text-align: center;
    margin: 24px 0 16px;
    background: linear-gradient(90deg, #fc8e57 0.61%, #ffd651 59.15%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 410px;
      font-size: 46px;
      line-height: 63px;
      text-align: start;
      margin: 0 0 16px;
    }
  `,
  Subtitle: styled.p`
    color: #dfecff;
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 410px;
      font-size: 20px;
      line-height: 26px;
      text-align: start;
    }
  `,
  PhoneForm: styled(PhoneForm)`
    &:first-of-type > div {
      height: 60px;
      border-radius: 30px;
      border: none;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        width: 380px;
      }
    }

    &:last-of-type > button {
      width: 60px;
      height: 56px;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      position: absolute;
      top: 2px;
      right: 10px;
      margin-top: 0;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        right: 2px;
      }
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      align-items: start;
      width: 380px;

      .react-international-phone-input {
        font-size: 20px;
        line-height: 26px;
      }
    }
  `,
  SecureContainer: styled.div`
    margin: 32px 0 40px;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    justify-content: center;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      justify-content: start;
      gap: 16px;
    }
  `,
  SecureWrapper: styled.div`
    display: flex;
    padding: 8px 12px 8px 8px;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.15);
    max-width: 158px;
    width: 100%;

    img {
      width: 36px;
      height: 36px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 182px;
    }
  `,
  SecureTextWrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  SecureTitle: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${Color.WHITE};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 18px;
    }
  `,
  SecureText: styled.p<TTextProps>`
    font-size: ${({ hasOverSizeFont }) => (hasOverSizeFont ? 12 : 13)}px;
    font-weight: 400;
    line-height: 16px;
    color: #dfecff;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 14px;
    }
  `,
  TopButton: styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: #35319f;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;

    img {
      width: 20px;
      height: 20px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: none;
    }
  `,
  Button: styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: #35319f;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;

    img {
      width: 20px;
      height: 20px;
    }
  `,
  PhoneFormV2: styled(PhoneForm)`
    &:first-of-type > div {
      height: 60px;
      border-radius: 30px;
      border: none;
    }

    button {
      margin-top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      color: #35319f;
      font-size: 17px;
      font-weight: 700;
      line-height: 24px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      button {
        width: 380px;
        font-size: 20px;
        line-height: 26px;

        img {
          width: 24px;
          height: 24px;
        }
      }
      .react-international-phone-input {
        font-size: 20px;
        line-height: 26px;
      }
      .react-international-phone-input-container {
        width: 380px;
      }
      .react-international-phone-country-selector-button {
        width: 62px;
      }
    }
  `,
  FormTitle: styled.h3`
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: #17202a;
    margin: 30px 0 16px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 32px;
      line-height: 40px;
    }
  `,
  Text: styled.p`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: #616a77;
    text-align: center;
    margin-bottom: 24px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 17px;
      line-height: 24px;
      max-width: 380px;
    }
  `,
  Compatible: styled.img`
    height: 64px;
    max-width: 327px;
    width: 100%;
    aspect-ratio: 327/64;
    margin-bottom: 40px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 56px;
    }
  `,
  CopyrightContainer: styled.div`
    padding-bottom: 80px;

    & p:first-of-type {
      margin-bottom: 8px;
    }
  `,
  CopyrightText: styled.p`
    color: #616a77;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  `,
}
