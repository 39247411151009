import React, { useDeferredValue, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  GET_STATUS,
  SEND_USER_EMAIL,
  sendUserEmailAction,
} from 'root-redux/actions/user'
import {
  selectActionList,
  selectAnswers,
  selectError,
} from 'root-redux/selects/common'

import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'
import { useDynamicOBConfig } from 'hooks/common/useDynamicOBConfig'
import { useEmailInputField } from 'hooks/common/useEmailInputField'
import { useUserData } from 'hooks/user/useUserData'

import { formatPhoneNumber } from 'helpers/phone'

import { eventLogger } from 'services/eventLogger.service'

import { getFilteredEmailDomains } from 'modules/email/helpers'

import { Input } from 'components/Input'
import { Modal } from 'components/Modal'
import { PoliciesGroup } from 'components/PoliciesGroup'
import { SvgImage } from 'components/SvgImage'

import customersImg from 'assets/images/sprite/customers.svg'
import lockImg from 'assets/images/sprite/lock.svg'

import { PageId } from 'root-constants/pages'

import { StyledEmailVariant4 as S } from './EmailVariant4.styles'

export const EmailVariant4: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const error = useSelector(selectError)
  const answers = useSelector(selectAnswers)
  const fetchingActionsList = useSelector(selectActionList)

  const [areEmailTipsVisible, setAreEmailTipsVisible] = useState(false)
  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  const [email, , validateEmail] = useEmailInputField()
  const deferredEmail = useDeferredValue(email.value)
  const { userPhoneNumber } = useUserData()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()
  const { title, subtitle, buttonText } = useDynamicOBConfig()

  const isStatusFetching =
    fetchingActionsList?.includes(SEND_USER_EMAIL) ||
    fetchingActionsList?.includes(GET_STATUS)

  const isButtonDisabled = !email.value || !email.isValid || isStatusFetching
  const filteredEmailDomains = getFilteredEmailDomains(deferredEmail)
  const [date, time] = (answers?.[PageId.LOCATION_SEARCH_2] || []) as string[]
  const formattedPhoneNumber = formatPhoneNumber(userPhoneNumber)

  useEffect(() => {
    eventLogger.logEmailPageShown()
  }, [])

  const handleChange = ({ target: { value } }) => {
    validateEmail(value)
    setAreEmailTipsVisible(true)
  }

  const handlePrefilledEmail = ({ currentTarget: { value } }) => {
    validateEmail(value)
    setAreEmailTipsVisible(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!email.isValid || isStatusFetching) return

    dispatch(
      sendUserEmailAction({
        email: email.value,
        isPersonalDataAllowed,
        unsuccessCallback: () => setIsErrorModalShown(true),
      }),
    )
  }

  return (
    <>
      <S.Wrapper>
        <S.Content>
          <S.Title>{title || t`onboarding.email.locationFound`}</S.Title>
          <S.Subtitle>
            {subtitle || (
              <Trans
                i18nKey="onboarding.email.phoneNumber"
                values={{
                  phoneNumber: formattedPhoneNumber,
                }}
              />
            )}
          </S.Subtitle>
          <S.Date>{t('onboarding.email.date', { date, time })}</S.Date>

          <S.Form onSubmit={handleSubmit}>
            <S.InputTitle>
              <Trans
                i18nKey="onboarding.email.title4"
                values={{
                  phoneNumber: formattedPhoneNumber,
                }}
              />
            </S.InputTitle>

            <S.InputWrapper>
              <Input
                className="no-validation-icon"
                value={email.value}
                isValid={email.isValid}
                validationText={email.validationText}
                placeholder={t`onboarding.email.placeholder`}
                autoComplete="off"
                onChange={handleChange}
              />
              {areEmailTipsVisible && (
                <S.AutoCompleteWrapper>
                  {filteredEmailDomains.map((value) => (
                    <S.AutoCompleteButton
                      type="button"
                      key={value}
                      value={value}
                      onMouseDown={handlePrefilledEmail}
                    >
                      <S.AutoCompleteButtonTextWrapper>
                        {value}
                      </S.AutoCompleteButtonTextWrapper>
                    </S.AutoCompleteButton>
                  ))}
                </S.AutoCompleteWrapper>
              )}
            </S.InputWrapper>

            <S.Note>
              <S.LockImg svg={lockImg} />
              <S.Description>{t`onboarding.email.note`}</S.Description>
            </S.Note>

            <S.Button type="submit" disabled={isButtonDisabled}>
              {buttonText || t`actions.continue`}
            </S.Button>
          </S.Form>
        </S.Content>

        <S.DisclaimerContainer>
          <SvgImage svg={customersImg} width={24} />
          <S.Disclaimer>{t`onboarding.email.peopleLocated`}</S.Disclaimer>
        </S.DisclaimerContainer>

        <PoliciesGroup />
      </S.Wrapper>
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
      >
        {error}
      </Modal>
    </>
  )
}
