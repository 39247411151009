import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import teenagersImage from 'assets/images/social-proof-8.jpg'

import { StyledSocialProofVariant8 as S } from './SocialProofVariant8.styles'
import { PAGE_NAMES } from './constants'

export const SocialProofVariant8: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAMES[pageId],
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <img src={teenagersImage} alt="teenagers" />
      </S.ImageContainer>
      <S.Column>
        <S.Text>
          <Trans i18nKey="onboarding.socialProof.variant8.paragraph1" />
        </S.Text>
        <S.Text>
          <Trans i18nKey="onboarding.socialProof.variant8.paragraph2" />
        </S.Text>
        <S.Note>
          <Trans i18nKey="onboarding.socialProof.variant8.note" />
        </S.Note>
        <Button onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.Column>
    </S.Wrapper>
  )
}
