import React, { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { FeaturesReady } from '@growthbook/growthbook-react'

import { useDynamicPaywallConfig } from 'hooks/common/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/common/useGetPrices'
import { usePaywall } from 'hooks/common/usePaywall'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useUserData } from 'hooks/user/useUserData'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { DesktopProvider } from 'components/DesktopProvider/DesktopProvider'
import { ReviewsSlider } from 'components/ReviewsSlider'
import { Spinner } from 'components/Spinner'

import { CDN_FOLDER_LINK, Images, SubscriptionTag } from 'root-constants/common'

import { Benefits } from '../../components/benefits'
import {
  FEW_GOALS_IMG_ASPECT_RATIO,
  GOAL_TO_MARKUP_MAP,
  HIGHER_SUBSCRIPTION_BLOCK_SCROLL_VIEW,
  PLAN_ITEMS,
} from '../../constants'
import { StyledSubscriptionsVariant2 as S } from './SubscriptionsVariant2.styles'

export const SubscriptionsVariant2: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const subscriptionBlockRef = useRef<HTMLDivElement>(null)
  const imageContainerBlockRef = useRef<HTMLDivElement>(null)

  const { subscriptions, language } = usePurchaseStore()
  const { hasPrices, handleShowPayment } = usePaywall(nextPagePath)
  const { singleUserGoal } = useUserData()
  const { subscriptionBlockType, subscriptionBlockTitle } =
    useDynamicPaywallConfig()
  useGetPrices({ tags: SubscriptionTag.NO_TAX })

  const { goalImageLink, aspectRatio } = singleUserGoal
    ? {
        goalImageLink: `${CDN_FOLDER_LINK}${GOAL_TO_MARKUP_MAP[singleUserGoal].img}_${language}.webp`,
        aspectRatio: GOAL_TO_MARKUP_MAP[singleUserGoal].aspectRatio,
      }
    : {
        goalImageLink: `${CDN_FOLDER_LINK}${Images.FEW_GOALS_IMG}_${language}.webp`,
        aspectRatio: FEW_GOALS_IMG_ASPECT_RATIO,
      }

  const goalLaptopImageLink = singleUserGoal
    ? `${CDN_FOLDER_LINK}${GOAL_TO_MARKUP_MAP[singleUserGoal].laptopImg}_${language}.jpg`
    : `${CDN_FOLDER_LINK}${Images.FEW_GOALS_IMG_LAPTOP}_${language}.jpg`

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
      onboardingId: singleUserGoal || 'multiple_choise',
    })
  }, [singleUserGoal, subscriptions])

  const handleTimerButtonClick = () => {
    const subscriptionBlockBorder = subscriptionBlockRef.current?.offsetTop

    if (subscriptionBlockBorder) {
      document.body.scrollTo({
        top: subscriptionBlockBorder - HIGHER_SUBSCRIPTION_BLOCK_SCROLL_VIEW,
        behavior: 'smooth',
      })
    }
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <FeaturesReady timeout={0} fallback={<div />}>
      <DesktopProvider>
        <S.SubheaderWithTimer
          elemForComparisonRef={imageContainerBlockRef}
          onClick={handleTimerButtonClick}
        />
        <S.Wrapper bgImgLink={goalLaptopImageLink}>
          <S.Column marginBottom={30} data-has-full-width>
            <S.Title>
              {singleUserGoal
                ? t(GOAL_TO_MARKUP_MAP[singleUserGoal].title)
                : t`subscriptions.variant2.titleMulti`}
            </S.Title>
            <S.Text>
              <Trans
                i18nKey={
                  singleUserGoal
                    ? GOAL_TO_MARKUP_MAP[singleUserGoal].subtitle
                    : 'subscriptions.variant2.subtitleMulti'
                }
              />
            </S.Text>
            <S.Timer />
          </S.Column>
          <S.ImageWrapper
            aspectRatio={aspectRatio}
            ref={imageContainerBlockRef}
          >
            <img src={goalImageLink} alt="map with locations" />
          </S.ImageWrapper>
          <S.Column data-has-bg data-has-full-width>
            <Benefits hasLaptopStyles />
            <S.Sharing>
              <Trans i18nKey="subscriptions.variant2.shareBlock" />
            </S.Sharing>
          </S.Column>
          <S.Column>
            <S.StyleContainer>
              <S.Subtitle>
                {subscriptionBlockTitle ||
                  t`subscriptions.variant2.titlePlanBlock`}
              </S.Subtitle>

              <S.PlanBlockWrapper ref={subscriptionBlockRef}>
                <S.SelectPlanBlock
                  SelectPlanItem={PLAN_ITEMS[subscriptionBlockType]}
                />
              </S.PlanBlockWrapper>
              <S.Button
                onClick={handleShowPayment}
                marginBottom={12}
                laptopMarginBottom={20}
              >
                {t`actions.start`}
              </S.Button>
              <S.SubscriptionDescription />
            </S.StyleContainer>
          </S.Column>

          <S.Column>
            <S.Guarantee />
            <ReviewsSlider hasLaptopStyles />
            <S.Button
              onClick={handleShowPayment}
              marginBottom={24}
              laptopMarginBottom={54}
            >
              {t`actions.start`}
            </S.Button>
            <S.Security />
          </S.Column>
        </S.Wrapper>
      </DesktopProvider>
    </FeaturesReady>
  )
}
