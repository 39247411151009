import styled from 'styled-components'

import { Divider } from 'modules/payment/components/Divider'

import { Button } from 'components/Button'

import linkIcon from 'assets/images/link-pay.png'

import { MediaBreakpoint } from 'root-constants/common'

export const StyledLinkPaymentButton = {
  Wrapper: styled.div`
    width: 100%;
  `,
  Divider: styled(Divider)`
    margin: 25px 0;
  `,
  Button: styled(Button)`
    position: relative;
    background-color: #61d17a;
    border-radius: 28px;
    height: 56px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 62px;
      height: 21px;
      background-image: url(${linkIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 460px;
    }
  `,
}
