import styled from 'styled-components'

import { baseColumnStyles } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants/common'

export const StyledEmailAccount = {
  Wrapper: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
    position: relative;
    color: var(--base-text-color);

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      position: relative;
      width: 400px;
      padding: 25px 0 40px;
    }
  `,
  Title: styled.h1`
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: ${({ theme }) => theme.locales?.chineseTextFontWeight || 700};
    line-height: 36px;
    text-align: center;
    color: ${({ theme }) => theme.colors.accountTitleColor};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-weight: 800;
      font-size: 48px;
      line-height: 65px;
      color: ${Color.DEFAULT_TEXT};
    }
  `,
  Text: styled.p`
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 20px;
    }
  `,
  Description: styled.span`
    position: relative;
    display: block;
    margin-bottom: 12px;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
    opacity: 0.6;

    a {
      color: inherit;
      text-decoration: underline;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 13px;
      margin-bottom: 20px;
    }
  `,
  Footer: styled.footer`
    margin-top: 30px;
    color: ${({ theme }) => theme.colors.tertiaryText};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      color: ${Color.DEFAULT_TEXT};

      button {
        width: 460px;
        height: 76px;
        font-size: 24px;
        line-height: 76px;
      }
    }
  `,
}
