import React from 'react'
import QRCode from 'react-qr-code'
import { useSelector } from 'react-redux'

import { selectOneLink } from 'root-redux/selects/user'

import { StyledQRCode as S } from './QRCode.styles'

export const QrCode: React.FC = () => {
  const oneLink = useSelector(selectOneLink)

  return (
    <S.Wrapper>
      <QRCode size={366} value={oneLink} viewBox="0 0 366 366" />
    </S.Wrapper>
  )
}
