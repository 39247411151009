import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import YouTube from 'react-youtube'

import { usePageInfo } from 'providers/PageInfoProvider'

import { useGetPrices } from 'hooks/common/useGetPrices'
import { usePaywall } from 'hooks/common/usePaywall'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useScrollToAnchor } from 'hooks/ui/useScrollToAnchor'
import { useViewportValue } from 'hooks/ui/useViewportValue'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import {
  BenefitsVariant1,
  BenefitsVariant2,
  BenefitsVariant4,
} from 'modules/subscriptions/components/google/Benefits'
import { GoodHands } from 'modules/subscriptions/components/google/GoodHands'
import { StepsVariant1 } from 'modules/subscriptions/components/google/Steps'
import { SubscriptionsBlockVariant1 } from 'modules/subscriptions/components/google/SubscriptionsBlockVariant1'
import {
  UNIQUE_VIEWS_NUMBER,
  YOUTUBE_VIDEO_ID,
} from 'modules/subscriptions/constants'
import { GEOLOCATION_BENEFITS } from 'modules/subscriptions/pages/SubscriptionsGoogleVariant3/constants'

import { ReviewsSlider3 } from 'components/ReviewsSlider'
import { Security } from 'components/Security'
import { Spinner } from 'components/Spinner'

import featuredImage from 'assets/images/featured.png'
import leftLeaf from 'assets/images/left-golden-leaf.png'
import longLineIcon from 'assets/images/long-line-v2.png'
import lineIcon from 'assets/images/long-line.png'
import rightLeaf from 'assets/images/right-golden-leaf.png'

import { goTo } from 'browser-history'
import {
  CDN_FOLDER_LINK,
  Images,
  Language,
  SubscriptionTag,
} from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledSubscriptionsGoogleVariant3 as S } from './SubscriptionsGoogleVariant3.styles'

export const SubscriptionsGoogleVariant3: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const scrollToElement = useRef<HTMLDivElement>(null)

  const [phoneNumber, setPhoneNumber] = useState('')
  const [videoStartCounter, setVideoStartCounter] = useState(0)

  const { hasUserEmail, language, subscriptions } = usePurchaseStore()
  const { hasPrices } = usePaywall(nextPagePath)
  const { currentPaymentPageId } = usePageInfo()
  useGetPrices({ tags: SubscriptionTag.NO_TAX })
  useScrollToAnchor(hasPrices)
  useViewportValue()

  useLayoutEffect(() => {
    if (hasUserEmail) {
      goTo({ pathname: currentPaymentPageId, search })
    }
  }, [search, hasUserEmail, currentPaymentPageId])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleButtonScroll = () => {
    scrollToElement.current?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }

  const handleVideoClick = () => {
    setVideoStartCounter((prevState) => prevState + 1)
    if (videoStartCounter < UNIQUE_VIEWS_NUMBER) {
      eventLogger.logVideoPlayStarted()
    }
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper id="1">
      <S.BurgerMenu />
      <S.Content>
        <S.Column>
          <S.Title>
            <Trans i18nKey="subscriptions.subscriptionsGoogle.variant1.getLocation" />
          </S.Title>
          <S.Text>
            <Trans i18nKey="subscriptions.subscriptionsGoogle.variant1.allYouNeed" />
          </S.Text>

          <S.Tracker ref={scrollToElement}>
            <img src={leftLeaf} alt="icon" />
            <p>
              <Trans i18nKey="onboarding.introGoogle4.strongPhoneTracker" />
            </p>
            <img src={rightLeaf} alt="icon" />
          </S.Tracker>

          <S.PhoneForm
            buttonText="subscriptions.subscriptionsGoogle.variant1.locateTheNumber"
            pageId={CustomPageId.PHONE_NUMBER}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        </S.Column>

        <S.PhoneImageContainer>
          <img
            src={
              language === Language.DE
                ? `${CDN_FOLDER_LINK}${Images.GOOGLE_SUBS_PHONE}_${Language.EN}.jpg`
                : `${CDN_FOLDER_LINK}${Images.GOOGLE_SUBS_PHONE}_${language}.jpg`
            }
            alt="phone"
          />
        </S.PhoneImageContainer>

        <S.Column>
          <GoodHands />
        </S.Column>

        <BenefitsVariant1 />

        <S.Column>
          <BenefitsVariant2 benefits={GEOLOCATION_BENEFITS} />
          <S.FeaturedTitle>{t`subscriptions.subscriptionsGoogle.variant3.featured`}</S.FeaturedTitle>
          <S.FeaturedImage src={featuredImage} alt="featured" />
        </S.Column>

        <BenefitsVariant4 />
        <ReviewsSlider3 />
        <S.Button type="button" onClick={handleButtonScroll}>
          {t`subscriptions.subscriptionsGoogle.variant1.subscribe`}
        </S.Button>

        <S.VideoContainer>
          <YouTube videoId={YOUTUBE_VIDEO_ID} onPlay={handleVideoClick} />
        </S.VideoContainer>

        <S.Column id="3">
          <S.BenefitsTitle>{t`onboarding.introGoogle4.howItWorks`}</S.BenefitsTitle>
          <StepsVariant1
            marginBottom={48}
            image={language === Language.EN ? lineIcon : longLineIcon}
            height={language === Language.EN ? 570 : 639}
          />
          <S.PhoneFormTitle id="4">{t`onboarding.introGoogle4.steps.enterThePhone`}</S.PhoneFormTitle>
        </S.Column>
        <S.PhoneForm
          buttonText="onboarding.introGoogle4.phoneButton"
          pageId={CustomPageId.PHONE_NUMBER}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
        <SubscriptionsBlockVariant1 ref={scrollToElement} />

        <S.Column>
          <Security />
        </S.Column>
      </S.Content>
    </S.Wrapper>
  )
}
