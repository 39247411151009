import styled, { createGlobalStyle } from 'styled-components'

import { BurgerMenu } from 'components/BurgerMenu'
import { StyledOption } from 'components/Option/Option.styles'
import { PoliciesGroup } from 'components/PoliciesGroup'
import { QuestionButton } from 'components/QuestionButton'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants/common'

export const LavenderBackgroundGlobalStyles = createGlobalStyle`
  :root {
    --base-background: #f5f5ff;
  }
`

export const StyledIntroVariant5 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100dvh;
    padding-bottom: 15px;
  `,
  ImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 375/308;
    margin: 0 auto;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    justify-content: flex-end;
    flex-grow: 1;
  `,
  Title: styled.h1`
    margin-bottom: 32px;
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
  `,
  OptionsContainer: styled(StyledOption.OptionsContainer)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;

    div {
      min-width: 150px;
      flex-shrink: 0;
    }
  `,
  QuestionButton: styled(QuestionButton)`
    justify-content: center;
    background-color: ${Color.WHITE};
    font-size: 18px;
  `,
  NotSureButton: styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};

    input:checked + & {
      color: ${Color.ACCENT_VIOLET};
    }
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 6px;
      right: 6px;
    }
  `,
  PoliciesGroup: styled(PoliciesGroup)`
    color: ${Color.LIGHT_TEXT};
  `,
}
