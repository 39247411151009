import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'
import { useDelayedContinue } from 'hooks/ui/useDelayedContinue'
import { useUserData } from 'hooks/user/useUserData'

import { TPageProps } from 'models/common.model'

import { DesktopProvider } from 'components/DesktopProvider'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import locationKidImage from 'assets/images/location-kid.jpg'
import locationImage from 'assets/images/location.jpg'

import { Confidence, Language, UserGoal } from 'root-constants/common'

import { StyledLocation as S } from './Location.styles'

export const LocationVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()

  const { userGoalsFormatted, singleUserGoal } = useUserData()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const isPartnerGoal = singleUserGoal === UserGoal.PARTNER

  const pageName = isPartnerGoal ? 'Partner location' : 'Family member location'

  const handleContinue = useNextStep({
    pageId,
    pageName,
    question: isPartnerGoal
      ? t('onboarding.location.questionPartner', { lng: Language.EN })
      : t('onboarding.location.question', { lng: Language.EN }),
    nextPagePath: !userGoalsFormatted.includes(UserGoal.KID)
      ? alternativePagePath
      : nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <DesktopProvider
      imageSrc={isPartnerGoal ? locationImage : locationKidImage}
    >
      <S.Wrapper>
        <S.Column>
          <S.Title>
            {isPartnerGoal
              ? t`onboarding.location.questionPartner`
              : t`onboarding.location.question`}
          </S.Title>
          <StyledOption.OptionsContainer>
            <Option
              {...optionProps}
              value={Confidence.YES}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t`commonComponents.yes`}</QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={Confidence.NO}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t`commonComponents.no`}</QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={Confidence.NOT_SURE}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t`commonComponents.notSure`}</QuestionButton>
            </Option>
          </StyledOption.OptionsContainer>
        </S.Column>
      </S.Wrapper>
    </DesktopProvider>
  )
}
