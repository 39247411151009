import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

import { useDynamicPaywallConfig } from 'hooks/common/useDynamicPaywallConfig'

import { BORDER_COLORS } from 'components/ReviewsSlider/constants'

import { StyledReviewsSlider as S } from './ReviewsSlider.styles'

type TProps = {
  marginBottom?: number
  hasLaptopStyles?: boolean
}

export const ReviewsSliderDynamic: React.FC<TProps> = ({
  marginBottom = 24,
  hasLaptopStyles = false,
}) => {
  const { customerReviews } = useDynamicPaywallConfig()

  return (
    <S.Wrapper
      marginBottom={marginBottom}
      data-has-laptop-styles={hasLaptopStyles}
    >
      <Swiper
        slidesPerView="auto"
        spaceBetween={20}
        centeredSlides
        breakpoints={{
          1200: {
            spaceBetween: 12,
            initialSlide: 1,
          },
        }}
      >
        {customerReviews?.map(
          ({ id, title, author, imageUrl, review }, index) => (
            <SwiperSlide key={id}>
              <S.Card
                borderColor={BORDER_COLORS[index % BORDER_COLORS.length]}
                data-has-laptop-styles={hasLaptopStyles}
              >
                <S.Avatar
                  width="66"
                  height="66"
                  src={imageUrl}
                  loading="lazy"
                />
                <S.CardTitle>{author}</S.CardTitle>
                <S.Recommendation text={title} />
                <S.CardText>{review}</S.CardText>
              </S.Card>
            </SwiperSlide>
          ),
        )}
      </Swiper>
    </S.Wrapper>
  )
}
