import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledSocialProofVariant22 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100dvh;
    padding-top: ${HEADER_HEIGHT}px;
  `,
  ImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 375/309;
    margin: 0 auto;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f5f5ff;
    padding-bottom: 32px;
    flex-grow: 1;
    width: 100%;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    width: 100%;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 16px;
  `,
  Text: styled.p`
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 16px;

    strong {
      font-weight: 800;
    }
  `,
  Description: styled.p`
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    margin-bottom: 20px;

    strong {
      display: block;
      font-weight: 800;
      font-size: 52px;
      line-height: 71px;
      margin-bottom: 10px;
    }

    b {
      font-weight: 600;
    }
  `,
  Note: styled.span`
    font-style: italic;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};
    margin-bottom: 21px;
  `,
}
