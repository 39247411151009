import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import onePlanForEveryone from 'assets/images/one-plan-for-everyone.webp'

import { StyledOnePlanForEveryone as S } from './OnePlanForEveryone.styles'

export const OnePlanForEveryoneVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const handleContinue = useNextStep({
    pageId,
    pageName: '',
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <img src={onePlanForEveryone} alt="one-plan-for-everyone" />
      </S.ImageContainer>
      <S.Column>
        <S.Text>{t`onboarding.onePlan.title`}</S.Text>
        <Button onClick={() => handleContinue('')}>Continue</Button>
      </S.Column>
    </S.Wrapper>
  )
}
