import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useIsFamylocateCohort } from 'hooks/common/useIsFamylocateCohort'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { ISelectPlanItemProps } from 'models/common.model'

import {
  DESCRIPTION_DATA,
  PLAN_ITEM_TITLES,
} from 'modules/subscriptions/components/upsell/constants'
import {
  OVERSIZE_CURRENCIES,
  OVERSIZE_LANGUAGES,
} from 'modules/subscriptions/constants'

import { PriceValue } from 'components/PriceValue'

import checkIconGrey from 'assets/images/check-icon-grey-v2.svg'
import checkIconViolet from 'assets/images/check-icon-violet-filled.svg'

import { Language } from 'root-constants/common'

import { StyledUpsellPlanItemVariant1 as S } from './UpsellPlanItemVariant1.styles'

export const UpsellPlanItemVariant1: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    discountPercent,
    product,
    mainPrices: { fullPrice },
  },
  isSelected,
}) => {
  const { t } = useTranslation()

  const { cohortToUse, currency, language } = usePurchaseStore()
  const isFamylocateCohort = useIsFamylocateCohort(cohortToUse)

  const oldPrice = (fullPrice / (discountPercent / 100)).toFixed(2)

  const hasSmallerFontSize = OVERSIZE_CURRENCIES.includes(currency)

  const hasOverSizeLanguage = OVERSIZE_LANGUAGES.includes(language)

  return (
    <S.Wrapper
      data-is-selected={isSelected}
      data-is-default={isDefault}
      isDifferentBG={isSelected && isDefault}
    >
      <S.TitleWrapper>
        <S.RadioButton
          checkIcon={isSelected ? checkIconViolet : checkIconGrey}
        />
        <S.Title hasOverSizeLanguage={hasOverSizeLanguage}>
          <Trans i18nKey={t(PLAN_ITEM_TITLES[product])} />
        </S.Title>
        <S.PriceWrapper>
          {isDefault && (
            <S.DiscountBadge
              isFamyLocate={isFamylocateCohort}
              isESLanguage={language === Language.ES}
            >
              <Trans i18nKey="subscriptions.upsell2.discount" />
            </S.DiscountBadge>
          )}
          <S.Price hasSmallerFontSize={hasSmallerFontSize}>
            <PriceValue value={fullPrice} currency={currency} />
          </S.Price>
          {!!discountPercent && (
            <S.OldPrice hasSmallerFontSize={hasSmallerFontSize}>
              <PriceValue value={oldPrice} currency={currency} />
            </S.OldPrice>
          )}
        </S.PriceWrapper>
      </S.TitleWrapper>
      {!!DESCRIPTION_DATA[product] && (
        <S.DescriptionWrapper>
          <img src={DESCRIPTION_DATA[product].img} alt="description" />
          <S.DescriptionText>
            {t(DESCRIPTION_DATA[product].text)}
          </S.DescriptionText>
        </S.DescriptionWrapper>
      )}
    </S.Wrapper>
  )
}
