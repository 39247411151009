import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { DesktopProvider } from 'components/DesktopProvider'

import sadGirlImg from 'assets/images/proof-1.webp'
import sadGirlLaptopImg from 'assets/images/social-proof-6-girl.jpg'

import { StyledSocialProof as S } from './SocialProof.styles'
import { PAGE_NAMES } from './constants'

export const SocialProofVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAMES[pageId],
    question: '',
    nextPagePath,
  })

  return (
    <DesktopProvider imageSrc={sadGirlLaptopImg}>
      <S.Wrapper>
        <S.Image src={sadGirlImg} alt="sad girl" />
        <S.Column>
          <S.Title laptopMarginBottom={30}>
            <Trans i18nKey="onboarding.socialProof.variant1.title" />
          </S.Title>
          <S.Text laptopMarginBottom={77}>
            <Trans i18nKey="onboarding.socialProof.variant1.paragraph" />
          </S.Text>
          <S.Note>{t`onboarding.socialProof.note`}</S.Note>
          <S.Button onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </S.Button>
        </S.Column>
      </S.Wrapper>
    </DesktopProvider>
  )
}
