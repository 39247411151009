import React from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'

import { useGetUserPosition } from 'hooks/user/useGetUserPosition'

import { GlobalStyleForMap } from 'pages/location-search/components/Map/Map.styles'

import { CurrentLocation } from '../CurrentLocation'

export const Map: React.FC = () => {
  const { lat, lng } = useGetUserPosition()

  return (
    <>
      <GlobalStyleForMap />
      <MapContainer center={[51.505, -0.09]} zoom={2} scrollWheelZoom={false}>
        <CurrentLocation position={[lat, lng]} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </>
  )
}
