import React from 'react'
import { useDispatch } from 'react-redux'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { ISelectPlanItemProps } from 'models/common.model'

import { setSelectedSubscriptionAction } from 'modules/payment/redux/actions/common'

import { OptionType } from 'components/Option'

import { StyledSelectPlanVariant1 as S } from './SelectPlanBlockVariant1.styles'

type TProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onSelect'> & {
  SelectPlanItem: React.FC<ISelectPlanItemProps>
}

export const SelectPlanBlockVariant1: React.FC<TProps> = ({
  SelectPlanItem,
}) => {
  const dispatch = useDispatch()
  const { subscriptions, selectedSubscriptionId } = usePurchaseStore()

  const handleChange = (value) => {
    const checkedSubscription = subscriptions.find(
      (subscription) => subscription.id === value,
    )
    checkedSubscription &&
      dispatch(setSelectedSubscriptionAction(checkedSubscription))
  }

  return (
    <S.Wrapper>
      {subscriptions.map((subscription) => (
        <S.Option
          type={OptionType.RADIO}
          name="subscription"
          value={subscription.id}
          key={subscription.id}
          onChange={handleChange}
        >
          <SelectPlanItem
            hasLaptopStyles
            subscription={subscription}
            isSelected={subscription.id === selectedSubscriptionId}
          />
        </S.Option>
      ))}
    </S.Wrapper>
  )
}
