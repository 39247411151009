import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color, MediaBreakpoint } from 'root-constants/common'

export const StyledTrustpilotVariant2 = {
  Wrapper: styled.div`
    max-width: 1440px;
    padding: 40px 180px;
    width: 100%;
    border-radius: 40px;
    background: linear-gradient(180deg, #574fcf 2.34%, #3734a6 100%);
    position: relative;
    margin-top: 40px;
    display: none;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 100px;
    }
  `,
  TrustpilotLogoWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    position: relative;
    top: 70px;
    right: 10px;
  `,
  TrustpilotLogo: styled.img`
    width: 167px;
  `,
  Review: styled.p`
    font-size: 17px;
    line-height: 24px;
    font-weight: 600;
    color: #dfecff;
  `,
  TrustpilotPhone: styled.img`
    max-width: 612px;
    position: relative;
    bottom: 90px;
  `,
  Button: styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: #35319f;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    width: 327px;
    position: relative;
    bottom: 40px;

    img {
      width: 24px;
      height: 24px;
    }
  `,
  Title: styled.h2`
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: ${Color.WHITE};
    margin-bottom: 32px;
  `,
  BenefitsContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  `,
  BenefitsWrapper: styled.div`
    width: 380px;
    padding: 12px 16px 12px 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 30px;
    background: rgba(201, 200, 255, 0.2);
    box-shadow: 0 8px 20px 0 rgba(27, 25, 88, 0.08);

    img {
      width: 117px;
      height: 100px;
    }
  `,
  Text: styled.p`
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${Color.WHITE};
  `,
}
