import { useEffect } from 'react'
import { useLocation } from 'react-router'

import { PageId } from 'root-constants/pages'

const NO_SCROLL_TO_TOP_PAGES: string[] = [
  PageId.INTRO_GOOGLE_4,
  PageId.SUBSCRIPTIONS_GOOGLE_2,
  PageId.SUBSCRIPTIONS_GOOGLE_3,
  PageId.SUBSCRIPTIONS_GOOGLE_4,
  PageId.SUBSCRIPTIONS_GOOGLE_5,
  PageId.SUBSCRIPTIONS_GOOGLE_6,
]

export const useScrollToTop = (): void => {
  const { pathname } = useLocation()

  useEffect(() => {
    const pageId = pathname.slice(1)
    if (NO_SCROLL_TO_TOP_PAGES.includes(pageId)) return

    document.body.scrollTo({ top: 0, behavior: 'instant' })
  }, [pathname])
}
