import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { BENEFITS_DATA } from 'pages/intro-google/components/Trustpilot/constants'

import gpsIcon from 'assets/images/gps-icon-blue.png'
import trustPilotLogo from 'assets/images/trustpilot-logo.png'
import trustPilotPhone from 'assets/images/trustpilot-phone.png'

import { CDN_FOLDER_LINK, Language } from 'root-constants/common'

import { StyledTrustpilotVariant1 as S } from './TrustpilotVariant1.styles'

type TProps = {
  handleScroll: () => void
}
export const TrustpilotVariant1: React.FC<TProps> = ({ handleScroll }) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const hasOverSizeFont = language === Language.PT

  return (
    <S.Wrapper>
      <S.TrustpilotLogoWrapper>
        <S.TrustpilotLogo src={trustPilotLogo} alt="logo" />
        <S.Review hasOverSizeFont={hasOverSizeFont}>
          {t`onboarding.introGoogle5.trustpilot.review`}
        </S.Review>
      </S.TrustpilotLogoWrapper>
      <S.TrustpilotPhone src={trustPilotPhone} alt="phone" />
      <S.Button onClick={handleScroll}>
        <img src={gpsIcon} alt="icon" />
        {t`onboarding.introGoogle5.locateNumber`}
      </S.Button>
      <S.Title>{t`onboarding.introGoogle5.trustpilot.title`}</S.Title>
      <S.BenefitsContainer>
        {BENEFITS_DATA.map(({ img, text }) => (
          <S.BenefitsWrapper key={text}>
            <img src={`${CDN_FOLDER_LINK}${img}_${language}.png`} alt="icon" />
            <S.Text>{t(text)}</S.Text>
          </S.BenefitsWrapper>
        ))}
      </S.BenefitsContainer>
    </S.Wrapper>
  )
}
