import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'
import { useDelayedContinue } from 'hooks/ui/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { PAGE_NAME } from 'pages/phone-habits/constants'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'

import { Confidence, Language } from 'root-constants/common'

import { StyledPhoneHabitsVariant2 as S } from './PhoneHabitsVariant2.styles'

export const PhoneHabitsVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.phoneHabits.question', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Content>
        <S.Column>
          <S.Title>{t`onboarding.phoneHabits.question`}</S.Title>
          <StyledOption.OptionsContainer>
            <Option
              {...optionProps}
              value={Confidence.YES}
              disabled={isAnswersDisabled}
            >
              <S.QuestionButton>{t`commonComponents.yes`}</S.QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={Confidence.NO}
              disabled={isAnswersDisabled}
            >
              <S.QuestionButton>{t`commonComponents.no`}</S.QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={Confidence.NOT_SURE}
              disabled={isAnswersDisabled}
            >
              <S.QuestionButton>{t`commonComponents.notSure`}</S.QuestionButton>
            </Option>
          </StyledOption.OptionsContainer>
        </S.Column>
      </S.Content>
    </S.Wrapper>
  )
}
