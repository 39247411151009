import React, { MutableRefObject, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { TimerVariant2 } from 'modules/subscriptions/components/common/Timer'
import {
  OVERSIZE_CURRENCIES,
  SUBSCRIPTION_BLOCK_SCROLL_VIEW,
} from 'modules/subscriptions/constants'

import { PriceValue } from 'components/PriceValue'

import giftBox from 'assets/images/gift-box.png'
import limitedOffer from 'assets/images/limited-offer.png'

import { goTo } from 'browser-history'
import {
  CDN_FOLDER_LINK,
  CURRENCY_SYMBOLS,
  Cohort,
  Color,
  Images,
  Language,
  TRIAL_IN_HOURS,
  TimeInterval,
  TrialPeriod,
} from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledSubscriptionsBlockVariant1 as S } from './SubscriptionsBlockVariant1.styles'
import { BENEFITS, TRIAL_DURATION_CONTEXTS } from './constants'

type TProps = {
  hasTimer?: boolean
  benefits?: string[]
}

export const SubscriptionsBlockVariant1 = forwardRef<HTMLDivElement, TProps>(
  ({ hasTimer = false, benefits = BENEFITS }, ref) => {
    const { t } = useTranslation()
    const { search } = useLocation()
    const insideRef = ref as MutableRefObject<HTMLParagraphElement>

    const {
      cohortToUse,
      trialPrice,
      oldTrialPrice,
      trialPeriodDays,
      currentPrice,
      oldPrice,
      currency,
      language,
    } = usePurchaseStore()

    const textParameters =
      trialPeriodDays >= TrialPeriod.THREE_DAYS
        ? {
            period: trialPeriodDays,
            context: TimeInterval.DAY,
          }
        : {
            period: TRIAL_IN_HOURS[trialPeriodDays],
          }

    const isThirteenOrFourteenCohort =
      cohortToUse === Cohort.GOOGLE_13 || cohortToUse === Cohort.GOOGLE_14

    const isSixteenCohort = cohortToUse === Cohort.GOOGLE_16

    const hasSmallerFontSize = OVERSIZE_CURRENCIES.includes(currency)

    const offerBadgeIcon = isSixteenCohort
      ? limitedOffer
      : `${CDN_FOLDER_LINK}${Images.UNLIMITED_OFFER_BADGE}_${language}.png`

    const handleClick = () => {
      if (isSixteenCohort) {
        goTo({ pathname: PageId.EMAIL_1, search })
        return
      }

      const phoneFormTitleBorder = insideRef.current?.offsetTop

      if (insideRef) {
        document.body.scrollTo({
          top: phoneFormTitleBorder - SUBSCRIPTION_BLOCK_SCROLL_VIEW,
          behavior: 'smooth',
        })
      }
    }

    return (
      <S.Wrapper>
        <S.Title>{t`subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.title`}</S.Title>
        {hasTimer && (
          <TimerVariant2
            backgroundColor={Color.GREY}
            hasShadow={false}
            marginBottom={24}
          />
        )}
        <S.Content hasOldPrice={isThirteenOrFourteenCohort}>
          {isThirteenOrFourteenCohort ? (
            <S.DiscountBadge
              src={`${CDN_FOLDER_LINK}${Images.GOOGLE_SUBS_DISCOUNT_BADGE}_${language}.png`}
              alt="badge"
            />
          ) : (
            <S.OfferBadge src={offerBadgeIcon} alt="badge" />
          )}
          <S.ContentTitle>{t`subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.contentTitle`}</S.ContentTitle>
          {isSixteenCohort && (
            <S.FamilyPlanWrapper>
              <img src={giftBox} alt="gift" />
              <S.FamilyTextWrapper>
                <S.FamilyTextTitle>{t`subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.familyPlan`}</S.FamilyTextTitle>
                <S.FamilyText>{t`subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.sharePremium`}</S.FamilyText>
              </S.FamilyTextWrapper>
            </S.FamilyPlanWrapper>
          )}
          {isThirteenOrFourteenCohort && (
            <S.OldPriceValue gridArea="oldTrialPrice">
              <PriceValue value={oldTrialPrice.fullPrice} currency={currency} />
            </S.OldPriceValue>
          )}
          <S.PriceValue
            gridArea="trialPrice"
            hasSmallerFontSize={hasSmallerFontSize}
          >
            <PriceValue value={trialPrice} currency={currency} />
          </S.PriceValue>
          <S.PriceText gridArea="trialText">
            {t(
              'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.trialPriceText',
              textParameters,
            )}
          </S.PriceText>

          <S.DurationBadge isFrenchLocale={language === Language.FR}>
            {t(
              'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.trialPeriod',
              textParameters,
            )}
          </S.DurationBadge>

          {isThirteenOrFourteenCohort && (
            <S.OldPriceValue gridArea="oldPrice">
              <PriceValue value={oldPrice.fullPrice} currency={currency} />
            </S.OldPriceValue>
          )}
          <S.PriceValue
            gridArea="fullPrice"
            hasSmallerFontSize={hasSmallerFontSize}
          >
            <PriceValue value={currentPrice} currency={currency} />
          </S.PriceValue>
          <S.PriceText gridArea="fullPriceText">
            {t`subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.fullPriceText`}
          </S.PriceText>

          <S.Disclaimer>
            {t(
              'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.disclaimer',
              {
                trialPrice,
                price: currentPrice,
                currencySymbol: CURRENCY_SYMBOLS[currency],
                context: TRIAL_DURATION_CONTEXTS[trialPeriodDays],
                minimumFractionDigits: 2,
              },
            )}
          </S.Disclaimer>

          <S.Benefits>
            {benefits.map((textPath) => (
              <S.BenefitItem key={textPath}>{t(textPath)}</S.BenefitItem>
            ))}
          </S.Benefits>

          <S.Button type="button" onClick={handleClick}>
            {t`subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.tryOffer`}
          </S.Button>
        </S.Content>
      </S.Wrapper>
    )
  },
)
