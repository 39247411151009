import styled from 'styled-components'

import { MediaBreakpoint } from 'root-constants/common'

type TProps = {
  marginBottom?: number
}
export const StyledBenefitsVariant1 = {
  Wrapper: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 32px;
    align-items: center;
    background: linear-gradient(174deg, #f0f5ff 13.11%, #f9f4ff 96.5%);
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 100%;
      width: 100%;
      padding: 100px 180px;
      gap: 48px;
      justify-content: center;
    }
  `,
  Title: styled.h2`
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    text-align: center;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 40px;
      line-height: 55px;
      font-weight: 800;
    }
  `,
  ContentWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 1080px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: start;
      gap: 48px;
    }
  `,
  Content: styled.div`
    display: flex;
    gap: 16px;

    img {
      width: 56px;
      height: 56px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      gap: 24px;
      max-width: 516px;
      width: 100%;

      img {
        width: 72px;
        height: 72px;
      }
    }
  `,
  TextWrapper: styled.div``,
  BenefitsTitle: styled.p`
    color: #5c90ff;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 4px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 24px;
      line-height: 33px;
      max-width: 420px;
      margin-bottom: 8px;
    }
  `,
  Subtitle: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 20px;
      line-height: 27px;
      max-width: 420px;
    }
  `,
}
