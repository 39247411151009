import React from 'react'

import { useDynamicPage } from 'hooks/common/useDynamicPage'

import { TPageProps } from 'models/common.model'

import { BurgerMenu } from 'components/BurgerMenu'
import { Option } from 'components/Option'
import { PoliciesGroup } from 'components/PoliciesGroup'

import { StyledDynamicIntroSingleChoice as S } from './DynamicIntroSingleChoice.styles'

export const DynamicIntroSingleChoice: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const {
    title,
    optionData,
    optionProps,
    isAnswersDisabled,
    imageUrls: [firstImage],
  } = useDynamicPage(pageId, nextPagePath)

  return (
    <S.Wrapper>
      <BurgerMenu />
      <S.ImageContainer imageSrc={firstImage} />
      <S.FixedContainer>
        <S.Column>
          <S.Title>{title}</S.Title>
          <S.OptionsContainer>
            {(optionData || []).map(({ value, text, id }, index, arr) => (
              <Option
                {...optionProps}
                key={id}
                value={value}
                disabled={isAnswersDisabled}
              >
                {index === arr.length - 1 ? (
                  <S.CustomButtonContainer>{text}</S.CustomButtonContainer>
                ) : (
                  <S.QuestionButton>{text}</S.QuestionButton>
                )}
              </Option>
            ))}
          </S.OptionsContainer>
          <PoliciesGroup />
        </S.Column>
      </S.FixedContainer>
    </S.Wrapper>
  )
}
