import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import 'react-international-phone/style.css'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { useScrollToAnchor } from 'hooks/ui/useScrollToAnchor'
import { useViewportValue } from 'hooks/ui/useViewportValue'

import { checkIsPhoneValid } from 'helpers/phone'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import {
  BACKGROUND_DIRECTION,
  BENEFITS_DATA_GOOGLE_4,
  PAGE_NAME,
  POSSIBILITIES_DATA,
  STEPS_DATA,
} from 'pages/intro-google/constants'

import { Button } from 'components/Button'
import { ProgressBarListModal } from 'components/ProgressBarList'
import { ReviewsSlider2 } from 'components/ReviewsSlider'
import { REVIEWS_DATA_SLIDER_VARIANT_3 } from 'components/ReviewsSlider/constants'
import { Security } from 'components/Security'
import { SwiperSlideVariant1 } from 'components/SwiperSlide'

import leftLeaf from 'assets/images/left-golden-leaf.png'
import lineIcon from 'assets/images/line-icon.png'
import rightLeaf from 'assets/images/right-golden-leaf.png'
import checkboxViolet from 'assets/images/sprite/check-icon-violet.svg'

import { Language } from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledIntroGoogleVariant4 as S } from './IntroGoogleVariant4.styles'

export const IntroGoogleVariant4: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const countryCode = useSelector(selectUserCountryCode)
  const language = useSelector(selectLanguage)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const isValid = checkIsPhoneValid(phoneNumber)

  const hasOverSizeFont = language === Language.AR

  useViewportValue()
  useScrollToAnchor(true)

  const handleChange = (value: string) => {
    setPhoneNumber(value)
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!isFocused && !isValid) {
      setIsFocused(true)
      return
    }
    if (isFocused && !isValid) {
      return
    }
    setIsSubmitted(true)
    eventLogger.logCheckNumberClicked()
  }

  return (
    <S.Wrapper id="1">
      <S.BurgerMenu />
      <S.Title hasOverSizeFont={hasOverSizeFont}>
        <Trans i18nKey="onboarding.introGoogle4.title" />
      </S.Title>
      <S.Column>
        <S.TrackerWrapper>
          <img src={leftLeaf} alt="icon" />
          <p>
            <Trans i18nKey="onboarding.introGoogle4.strongPhoneTracker" />
          </p>
          <img src={rightLeaf} alt="icon" />
        </S.TrackerWrapper>
        <S.PhoneForm onSubmit={handleSubmit}>
          <S.PhoneText>{t`onboarding.introGoogle3.phoneText2`}</S.PhoneText>
          <S.PhoneNumberInput
            defaultCountry={countryCode.toLowerCase()}
            value={phoneNumber}
            onChange={handleChange}
            onFocus={handleFocus}
          />
          {isFocused && !isValid && (
            <S.PhoneErrorText>
              {t`onboarding.introGoogle3.phoneError`}
            </S.PhoneErrorText>
          )}
          <S.Text>{t`onboarding.introGoogle4.worksWithAll`}</S.Text>
          <Button type="submit">{t`onboarding.introGoogle3.phoneButton`}</Button>
        </S.PhoneForm>
      </S.Column>
      <S.Subtitle id="2">
        <Trans i18nKey="onboarding.introGoogle4.famioAllows" />
      </S.Subtitle>
      <S.BenefitsWrapper>
        <S.List>
          {BENEFITS_DATA_GOOGLE_4.map(({ title, subtitle }) => (
            <S.ListItem key={title}>
              <S.ListIcon svg={checkboxViolet} width={23} />
              <S.ListTitle>
                <Trans i18nKey={title} />
              </S.ListTitle>

              <S.ListText>
                <Trans i18nKey={subtitle} />
              </S.ListText>
            </S.ListItem>
          ))}
        </S.List>
      </S.BenefitsWrapper>
      <SwiperSlideVariant1 />
      <S.PossibilitiesWrapper>
        <S.PossibilitiesTitle>{t`onboarding.introGoogle4.title2`}</S.PossibilitiesTitle>
        <S.PossibilitiesContainer>
          {POSSIBILITIES_DATA.map(({ image, title, subtitle }) => (
            <div key={title}>
              <S.PossibilitiesIconContainer>
                <img src={image} alt="icon" />
              </S.PossibilitiesIconContainer>
              <S.ContainerTitle>{t(title)}</S.ContainerTitle>
              <S.ContainerSubtitle>{t(subtitle)}</S.ContainerSubtitle>
            </div>
          ))}
        </S.PossibilitiesContainer>
      </S.PossibilitiesWrapper>
      <S.Subtitle marginBottom={24}>
        <Trans i18nKey="onboarding.introGoogle4.joinMillions" />
      </S.Subtitle>
      <ReviewsSlider2 reviews={REVIEWS_DATA_SLIDER_VARIANT_3} />
      <S.Subtitle marginTop={24} id="3">
        <Trans i18nKey="onboarding.introGoogle4.howItWorks" />
      </S.Subtitle>
      <S.StepsWrapper>
        <img src={lineIcon} alt="icon" />
        <S.StepsContent>
          {STEPS_DATA.map(({ title, subtitle }) => (
            <div key={title}>
              <S.StepsTitle>{t(title)}</S.StepsTitle>
              <S.StepsSubtitle isLanguageDe={language === Language.DE}>
                {t(subtitle)}
              </S.StepsSubtitle>
            </div>
          ))}
        </S.StepsContent>
      </S.StepsWrapper>
      <S.BottomPhoneForm onSubmit={handleSubmit} id="4">
        <S.PhoneText>{t`onboarding.introGoogle3.phoneText2`}</S.PhoneText>
        <S.PhoneNumberInput
          defaultCountry={countryCode.toLowerCase()}
          value={phoneNumber}
          onChange={handleChange}
          onFocus={handleFocus}
        />
        {isFocused && !isValid && (
          <S.PhoneErrorText>
            {t`onboarding.introGoogle3.phoneError`}
          </S.PhoneErrorText>
        )}
        <S.Button
          type="submit"
          backgroundDirection={BACKGROUND_DIRECTION[language] || '85px center'}
        >
          {t`onboarding.introGoogle4.phoneButton`}
        </S.Button>
      </S.BottomPhoneForm>
      <S.SecurityWrapper>
        <Security />
      </S.SecurityWrapper>
      <ProgressBarListModal
        isShown={isSubmitted}
        nextPagePath={nextPagePath}
        pageId={CustomPageId.PHONE_NUMBER}
        pageName={PAGE_NAME}
        phoneNumber={phoneNumber}
      />
    </S.Wrapper>
  )
}
