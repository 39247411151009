import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { PaymentRequestButton } from 'modules/payment/components/PaymentRequestButton'

import { PhoneNumberLabel } from 'components/PhoneNumberLabel'
import { PriceValue } from 'components/PriceValue'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import paymentServicesImage from 'assets/images/payment-services.png'

import {
  CURRENCY_SYMBOLS,
  Currency,
  TRIAL_IN_HOURS,
  TimeInterval,
  TrialPeriod,
} from 'root-constants/common'

import { StyledPaymentFlowsGoogleVariant3 as S } from './PaymentFlowsGoogleVariant3.styles'

export const PaymentFlowsGoogleVariant3: React.FC = () => {
  const { t } = useTranslation()

  const {
    selectedSubscriptionId,
    currentPrice,
    currency,
    trialPrice,
    oldTrialPrice,
    trialPeriodDays,
  } = usePurchaseStore()

  const isMexicanPeso = currency === Currency.MXN

  const textParameters =
    trialPeriodDays >= TrialPeriod.THREE_DAYS
      ? {
          period: trialPeriodDays,
          context: TimeInterval.DAY,
        }
      : {
          period: TRIAL_IN_HOURS[trialPeriodDays],
        }

  return (
    <S.Wrapper>
      <PhoneNumberLabel />
      <S.PriceContainer>
        <S.Text gridArea="priceDescription">
          {t('payment.google.accessDuration', { trialPeriodDays })}
        </S.Text>
        <S.Text gridArea="oldPriceValue" justifySelf="flex-end">
          <PriceValue value={oldTrialPrice.fullPrice} currency={currency} />
        </S.Text>

        <S.DiscountText gridArea="discountText">{t`payment.google.discount65`}</S.DiscountText>
        <S.DiscountText gridArea="discountValue" justifySelf="flex-end">
          -
          <PriceValue
            value={oldTrialPrice.amountOfDiscount}
            currency={currency}
          />
        </S.DiscountText>
        <S.Divider />

        <S.TotalText gridArea="totalText" isMexicanPeso={isMexicanPeso}>
          {t`payment.google.total`}
        </S.TotalText>
        <S.TotalText
          gridArea="totalValue"
          isMexicanPeso={isMexicanPeso}
          justifySelf="flex-end"
        >
          <PriceValue value={trialPrice} currency={currency} />
        </S.TotalText>
        <S.Text gridArea="trialText" justifySelf="flex-end">
          {t('payment.trial', textParameters)}
        </S.Text>
      </S.PriceContainer>

      <S.Disclaimer>
        {t('payment.googleFlowDisclaimer', {
          period: TRIAL_IN_HOURS[trialPeriodDays],
          trialPeriodDays,
          currencySymbol: CURRENCY_SYMBOLS[currency],
          price: currentPrice,
          minimumFractionDigits: 2,
          context:
            trialPeriodDays > TrialPeriod.THREE_DAYS
              ? TimeInterval.WEEK
              : TimeInterval.DAY,
        })}
      </S.Disclaimer>

      <S.StripePaymentForm />
      <PaymentRequestButton isDividerVisible key={selectedSubscriptionId} />
      <S.Image
        src={paymentServicesImage}
        decoding="async"
        alt="payments services"
      />
      <S.Guarantee>{t`payment.guarantee`}</S.Guarantee>
      <S.BottomDisclaimer>
        <Trans
          i18nKey="payment.descriptionWithLink"
          components={{ a: <TermsOfUseLink /> }}
        />
      </S.BottomDisclaimer>
    </S.Wrapper>
  )
}
