import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { usePageInfo } from 'providers/PageInfoProvider'

import { useEmailInputField } from 'hooks/common/useEmailInputField'
import { useGetPrices } from 'hooks/common/useGetPrices'
import { usePaywall } from 'hooks/common/usePaywall'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useViewportValue } from 'hooks/ui/useViewportValue'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import {
  BenefitsVariant1,
  BenefitsVariant2,
  BenefitsVariant3,
} from 'modules/subscriptions/components/google/Benefits'
import { EmailPhoneForm } from 'modules/subscriptions/components/google/EmailPhoneForm'
import { StepsVariant1 } from 'modules/subscriptions/components/google/Steps'
import { SubscriptionsBlockVariant1 } from 'modules/subscriptions/components/google/SubscriptionsBlockVariant1'

import { Security } from 'components/Security'
import { Spinner } from 'components/Spinner'

import { goTo } from 'browser-history'
import {
  CDN_FOLDER_LINK,
  Images,
  Language,
  SubscriptionTag,
} from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledSubscriptionsGoogleVariant1 as S } from './SubscriptionsGoogleVariant1.styles'

export const SubscriptionsGoogleVariant1: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const scrollToElement = useRef<HTMLHeadingElement>(null)

  const [phoneNumber, setPhoneNumber] = useState('')

  const { hasUserEmail, language, subscriptions } = usePurchaseStore()

  const [email, , validateEmail] = useEmailInputField()
  const { hasPrices } = usePaywall(nextPagePath)
  const { currentPaymentPageId } = usePageInfo()
  useGetPrices({ tags: SubscriptionTag.NO_TAX })
  useViewportValue()

  useLayoutEffect(() => {
    if (hasUserEmail) {
      goTo({ pathname: currentPaymentPageId, search })
    }
  }, [search, hasUserEmail, currentPaymentPageId])

  useEffect(() => {
    if (!subscriptions.length || hasUserEmail) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [hasUserEmail, subscriptions])

  const handleButtonScroll = () => {
    scrollToElement.current?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.BurgerMenu />
      <S.WideColumn>
        <S.Title ref={scrollToElement}>
          <Trans i18nKey="subscriptions.subscriptionsGoogle.variant1.getLocation" />
        </S.Title>
        <S.Text>
          <Trans i18nKey="subscriptions.subscriptionsGoogle.variant1.allYouNeed" />
        </S.Text>
      </S.WideColumn>
      <EmailPhoneForm
        buttonText="subscriptions.subscriptionsGoogle.variant1.locateTheNumber"
        pageId={CustomPageId.PHONE_NUMBER}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        email={email}
        validateEmail={validateEmail}
      />
      <S.PhoneImageContainer>
        <img
          src={
            language === Language.DE
              ? `${CDN_FOLDER_LINK}${Images.GOOGLE_SUBS_PHONE}_${Language.EN}.jpg`
              : `${CDN_FOLDER_LINK}${Images.GOOGLE_SUBS_PHONE}_${language}.jpg`
          }
          alt="phone"
        />
      </S.PhoneImageContainer>
      <BenefitsVariant1 />
      <S.Column>
        <BenefitsVariant2 />
        <BenefitsVariant3 />
      </S.Column>
      <S.KidImageContainer>
        <img
          src={
            language === Language.DE
              ? `${CDN_FOLDER_LINK}${Images.GOOGLE_PHONE_KID}_${Language.EN}.png`
              : `${CDN_FOLDER_LINK}${Images.GOOGLE_PHONE_KID}_${language}.png`
          }
          alt="phone"
        />
      </S.KidImageContainer>
      <S.Column>
        <S.Button type="button" onClick={handleButtonScroll}>
          {t`subscriptions.subscriptionsGoogle.variant1.subscribe`}
        </S.Button>
        <S.CommonTitle>{t`onboarding.introGoogle4.howItWorks`}</S.CommonTitle>
        <StepsVariant1 />
        <S.EmailSubtitle>{t`onboarding.introGoogle4.steps.enterThePhone`}</S.EmailSubtitle>
      </S.Column>
      <EmailPhoneForm
        buttonText="onboarding.introGoogle4.phoneButton"
        pageId={CustomPageId.PHONE_NUMBER}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        email={email}
        validateEmail={validateEmail}
      />
      <SubscriptionsBlockVariant1 ref={scrollToElement} />
      <S.Column>
        <Security />
      </S.Column>
    </S.Wrapper>
  )
}
