import styled from 'styled-components'

import { SelectPlanBlockVariant1 } from 'modules/subscriptions/components/common/SelectPlanBlockVariant1'
import { SubscriptionDescriptionTrial } from 'modules/subscriptions/components/common/SubscriptionDescription'
import { TImageWrapperProps } from 'modules/subscriptions/types'

import { Button } from 'components/Button'

import bgImg from 'assets/images/subscriptions-bg.svg'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { BASE_COLUMN_WIDTH, MediaBreakpoint } from 'root-constants/common'

type TProps = {
  marginBottom?: number
}
export const StyledTrialSubscriptionsVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100%;
    padding-top: 96px;
    overflow-x: hidden;
  `,
  Column: styled.div<TProps>`
    ${baseColumnStyles};
    margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;

    &[data-has-bg='true'] {
      position: relative;

      ::before {
        content: '';
        display: block;
        width: 100vw;
        aspect-ratio: 1/1;
        background-image: url(${bgImg});
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        z-index: -1;
      }
    }
  `,
  ImageWrapper: styled.div<TImageWrapperProps>`
    width: 100%;
    aspect-ratio: ${({ aspectRatio }) => aspectRatio};
    max-height: 302px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${BASE_COLUMN_WIDTH}px;
    }
  `,
  Sharing: styled.p`
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    padding: 19px 0;
    margin-bottom: 37px;
    background-image: linear-gradient(
      266deg,
      rgba(225, 204, 252, 0.5) 45%,
      rgba(239, 208, 226, 0.5) 100%
    );
    border-radius: 16px;

    strong {
      font-weight: 800;
    }
  `,
  Title: styled.h1`
    font-size: 32px;
    line-height: 44px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 12px;

    br {
      display: none;
    }
  `,
  Text: styled.span`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-bottom: 28px;
  `,
  Subtitle: styled.h2`
    ${baseTitleStyles};
    margin: 0 -1px 40px;
  `,
  Button: styled(Button)<TProps>`
    height: 56px;
    line-height: 56px;
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
  `,
  PlanBlockWrapper: styled.div`
    width: 100%;
  `,
  SelectPlanBlock: styled(SelectPlanBlockVariant1)`
    margin-bottom: 20px;
  `,
  SubscriptionDescription: styled(SubscriptionDescriptionTrial)<TProps>`
    margin: 0 auto 32px;
  `,
}
