import styled from 'styled-components'

import { SelectPlanBlockVariant1 } from 'modules/subscriptions/components/common/SelectPlanBlockVariant1'
import {
  SubscriptionDescription,
  SubscriptionDescriptionTrial,
} from 'modules/subscriptions/components/common/SubscriptionDescription'
import { TImageWrapperProps } from 'modules/subscriptions/types'

import { Button } from 'components/Button'
import { Security } from 'components/Security'

import bgImg from 'assets/images/subscriptions-bg.svg'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledSubscriptionsVariant9 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100%;
    padding-top: ${HEADER_HEIGHT}px;
    overflow-x: hidden;
  `,
  Column: styled.div`
    ${baseColumnStyles};

    &[data-has-bg] {
      position: relative;

      ::before {
        content: '';
        display: block;
        width: 100vw;
        aspect-ratio: 1/1;
        background-image: url(${bgImg});
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        z-index: -1;
      }
    }
  `,
  Title: styled.h1`
    padding: 30px 0 16px;
    font-size: 32px;
    line-height: 44px;
    font-weight: 800;
    text-align: center;
  `,
  Subtitle: styled.h2`
    padding-bottom: 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  `,
  ImageWrapper: styled.div<TImageWrapperProps>`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    margin: 40px 0 18px;
    aspect-ratio: ${({ aspectRatio }) => aspectRatio};

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  SubscriptionsTitle: styled.h2`
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  `,
  Button: styled(Button)`
    height: 56px;
    line-height: 56px;
  `,
  PlanBlockWrapper: styled.div`
    width: 100%;
  `,
  SelectPlanBlock: styled(SelectPlanBlockVariant1)`
    margin-bottom: 28px;
  `,
  SubscriptionDescription: styled(SubscriptionDescription)`
    margin: 12px 0 40px;
  `,
  SubscriptionDescriptionTrial: styled(SubscriptionDescriptionTrial)`
    margin: 12px 0 40px;
  `,
  Security: styled(Security)`
    margin-top: 40px;
  `,
}
