import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import socialProofImageMobile from 'assets/images/social-proof-7.webp'

import { PageId } from 'root-constants/pages'

import { StyledSocialProof as S } from './SocialProof.styles'
import { PAGE_NAMES } from './constants'

export const SocialProofVariant7: React.FC<TPageProps> = ({ pageId }) => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAMES[pageId],
    question: '',
    nextPagePath: `${PageId.DATA_1}${search}`,
  })

  return (
    <S.Wrapper>
      <S.Image src={socialProofImageMobile} alt="users" />
      <S.Column>
        <S.Description>
          <Trans
            i18nKey="onboarding.socialProof.variant7.paragraph"
            components={{ strong: <strong />, b: <b />, br: <br /> }}
          />
        </S.Description>
        <S.Button onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </S.Button>
      </S.Column>
    </S.Wrapper>
  )
}
