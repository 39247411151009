import styled from 'styled-components'

import { StripePaymentProcessing } from 'components/StripePaymentProcessing'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { MediaBreakpoint } from 'root-constants/common'

type TStripePaymentProcessingProps = {
  hasOverSizeFont: boolean
}
export const StyledPaymentVariant6 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: 53px;
    min-height: 100%;
  `,
  Content: styled.div`
    ${baseColumnStyles};
  `,
  ThreeDSecureIframe: styled.iframe`
    height: calc(var(--full-height, 100vh) - 50px) !important;
    padding: 15px;
  `,
  StripePaymentProcessing: styled(
    StripePaymentProcessing,
  )<TStripePaymentProcessingProps>`
    h2 {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      color: #17202a;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 12px;
      }
    }
    h3 {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      color: #626262;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        font-size: 15px;
        line-height: 20px;
      }
    }
    button {
      font-size: ${({ hasOverSizeFont }) => (hasOverSizeFont ? 14 : 17)}px;
      font-weight: 700;
      line-height: 24px;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        width: 332px;
        font-size: 20px;
        line-height: 26px;
      }
    }
  `,
}
