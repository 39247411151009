import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { usePageInfo } from 'providers/PageInfoProvider'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'
import { selectAnswers, selectLanguage } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/ui/useDelayedContinue'

import { getDynamicPersonalization } from 'helpers/getDynamicPersonalization'

import { eventLogger } from 'services/eventLogger.service'

import { OptionType } from 'components/Option'

import { goTo } from 'browser-history'
import { CHECKBOX_CUSTOM_VALUES } from 'root-constants/common'

export const useDynamicPage = (pageId: string, nextPagePath: string) => {
  const { search } = useLocation()
  const dispatch = useDispatch()
  const language = useSelector(selectLanguage)
  const userAnswers = useSelector(selectAnswers)

  const [answers, setAnswers] = useState<string[]>([])
  const { currentPageIndex, steps } = usePageInfo()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const {
    optionType = OptionType.RADIO,
    customPageId,
    title = '',
    subtitle,
    buttonText,
    imageUrls,
    pageTexts,
    optionData,
  } = useMemo(() => {
    const dynamicPageConfig = steps[currentPageIndex].onboardingDynamicConfig

    return {
      optionType: dynamicPageConfig?.[language]?.optionType,
      customPageId: dynamicPageConfig?.[language]?.customPageId,
      title: getDynamicPersonalization(
        dynamicPageConfig?.[language]?.title,
        userAnswers,
        dynamicPageConfig?.[language]?.personalization,
      ),
      subtitle: getDynamicPersonalization(
        dynamicPageConfig?.[language]?.subtitle,
        userAnswers,
        dynamicPageConfig?.[language]?.personalization,
      ),
      buttonText: dynamicPageConfig?.[language]?.buttonText,
      imageUrls: dynamicPageConfig?.[language]?.imageUrls || [],
      pageTexts: (dynamicPageConfig?.[language]?.pageTexts || []).map((text) =>
        getDynamicPersonalization(
          text,
          userAnswers,
          dynamicPageConfig?.[language]?.personalization,
        ),
      ),
      optionData: dynamicPageConfig?.[language]?.optionData || [],
    }
  }, [steps, currentPageIndex, language, userAnswers])

  const allOptionValues = (optionData || [])
    .map(({ value }) => value)
    .filter((value) => !CHECKBOX_CUSTOM_VALUES.includes(value))

  useEffect(() => {
    setAnswers([])
    setIsAnswersDisabled(false)
  }, [pageId, setIsAnswersDisabled])

  const handleContinue = useCallback(
    (answer) => {
      dispatch(
        setAnswersAction({
          answers: answer,
          pageId: customPageId || pageId,
        }),
      )
      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: title,
        answers: answer,
        pageName: customPageId || pageId,
      })

      if (optionType === OptionType.CHECKBOX) {
        goTo(nextPagePath)
        return
      }

      const selectedAnswer = optionData?.find((obj) => obj.value === answer)
      const nextPage = selectedAnswer?.nextPageId
        ? `${selectedAnswer.nextPageId}${search}`
        : nextPagePath

      goTo(nextPage)
    },
    [
      customPageId,
      dispatch,
      nextPagePath,
      optionData,
      optionType,
      pageId,
      search,
      title,
    ],
  )

  const handleSingleChoice = useCallback(
    (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
    [handleChange, handleContinue, setIsAnswersDisabled],
  )

  const handleMultipleChoice = useCallback(
    (value: string, isChecked: boolean) => {
      if (isChecked) {
        setAnswers([...answers, value])
        return
      }

      const newValues = answers.filter((item) => item !== value)
      setAnswers(newValues)
    },
    [answers],
  )

  const handleCustomChoice = useCallback(
    (value: string, isChecked: boolean) => {
      if (isChecked) {
        CHECKBOX_CUSTOM_VALUES.includes(value)
          ? setAnswers(allOptionValues)
          : setAnswers([...answers, value])
        return
      }

      CHECKBOX_CUSTOM_VALUES.includes(value)
        ? setAnswers([])
        : setAnswers(answers.filter((item) => item !== value))
    },
    [allOptionValues, answers],
  )

  const isCustomChoice = useMemo(
    () => allOptionValues?.length !== optionData?.length,
    [allOptionValues?.length, optionData?.length],
  )

  const getHandler = useMemo(() => {
    if (optionType === OptionType.RADIO) {
      return handleSingleChoice
    }
    if (optionType === OptionType.CHECKBOX && !isCustomChoice) {
      return handleMultipleChoice
    }

    return handleCustomChoice
  }, [
    optionType,
    handleMultipleChoice,
    handleSingleChoice,
    handleCustomChoice,
    isCustomChoice,
  ])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: optionType,
      onChange: getHandler,
    }),
    [optionType, pageId, getHandler],
  )

  return {
    title,
    subtitle,
    buttonText,
    imageUrls,
    pageTexts,
    optionType,
    optionData,
    optionProps,
    answers,
    isAnswersDisabled,
    isCustomChoice,
    allOptionValues,
    handleContinue,
    customPageId,
  }
}
