import styled from 'styled-components'

import { QuestionButton } from 'components/QuestionButton'

import {
  baseAdaptiveColumnStyles,
  baseAdaptiveTitleStyles,
  basePageStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledCheatingVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    min-height: 100dvh;
  `,
  Content: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    background-color: #f5f5ff;
  `,
  Column: styled.div`
    ${baseAdaptiveColumnStyles};
    padding: 24px 25px;
  `,
  Title: styled.h1`
    ${baseAdaptiveTitleStyles};
    margin: 0 0 54px;
  `,
  QuestionButton: styled(QuestionButton)`
    background-color: ${Color.WHITE};
  `,
}
