import React from 'react'

import { useUserData } from 'hooks/user/useUserData'

import { TPageProps } from 'models/common.model'

import { RadioOptionPage } from 'pages/options-page/RadioOptionPage'

import {
  ALTERNATIVE_LOW_CONFIDENCE_OPTION_DATA,
  ChildFlow,
} from 'root-constants/common'

import { TITLES } from './constants'

export const SpecificZonesVariant2: React.FC<TPageProps> = (props) => {
  const { childFlow } = useUserData()

  return (
    <RadioOptionPage
      {...props}
      options={ALTERNATIVE_LOW_CONFIDENCE_OPTION_DATA}
      titlePath={TITLES[childFlow]}
      marginBottom={childFlow === ChildFlow.TEENAGE ? 21 : 54}
    />
  )
}
