import { useSelector } from 'react-redux'

import { selectHasUpsellOffer, selectLanguage } from 'root-redux/selects/common'
import {
  selectGeneralSubscriptionTrialPeriod,
  selectIsUpgradePassed,
  selectUserStatus,
} from 'root-redux/selects/user'

import { Language, TrialPeriod } from 'root-constants/common'

const UPSELL_LOCALES = [
  Language.EN,
  Language.ES,
  Language.DE,
  Language.FR,
  Language.PT,
  Language.IT,
]

export const useUpsellStatus = () => {
  const userStatus = useSelector(selectUserStatus)
  const isUpgradePassed = useSelector(selectIsUpgradePassed)
  const language = useSelector(selectLanguage)
  const hasUpsellOffer = useSelector(selectHasUpsellOffer)
  const userTrialPeriod = useSelector(selectGeneralSubscriptionTrialPeriod)

  return (
    userStatus?.hasSubscription &&
    hasUpsellOffer &&
    userStatus.upsell.isUpsellAvailable &&
    !userStatus.upsell.isUpsellPurchased &&
    !isUpgradePassed &&
    UPSELL_LOCALES.includes(language) &&
    userTrialPeriod !== TrialPeriod.ONE_YEAR
  )
}
