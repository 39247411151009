import styled, { css } from 'styled-components'

import { MediaBreakpoint } from 'root-constants/common'

const progressValueStyles = css`
  border-radius: 3px;
  transition: all 0.2s ease-out;
  background: ${({ theme }) => theme.colors.activeBackground};
`

type TProps = {
  isFinished: boolean
}
export const StyledSearchUser = {
  Wrapper: styled.div<TProps>`
    max-width: 327px;
    width: 100%;
    display: ${({ isFinished }) => (isFinished ? 'none' : 'flex')};
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-radius: 24px;
    background: ${({ theme }) => theme.colors.locationPopupBackground};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;
    z-index: 3;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 420px;
      padding: 40px;
      align-items: center;
    }
  `,
  PhoneNumberWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Title: styled.p`
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.defaultTextColor};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 24px;
      line-height: 32px;
    }
  `,
  PhoneNumber: styled.p`
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
    color: ${({ theme }) => theme.colors.defaultTextColor};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 38px;
      line-height: 48px;
    }
  `,
  StepsWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  Steps: styled.div`
    display: flex;
    gap: 12px;

    img {
      max-width: 23px;
      max-height: 23px;
    }
  `,
  Text: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.defaultTextColor};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 17px;
      line-height: 24px;
    }
  `,
  ProgressBarContainer: styled.div`
    max-width: 279px;
    min-height: 8px;
    width: 100%;
    border-radius: 16px;
    background: ${({ theme }) => theme.colors.defaultProgressBarBackground};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 340px;
    }
  `,
  ProgressBar: styled.progress`
    width: 100%;
    height: 8px;
    border-radius: 16px;
    appearance: none;
    border: none;
    display: block;
    overflow: hidden;
    background: ${({ theme }) => theme.colors.defaultProgressBarBackground};
    position: relative;

    &::-webkit-progress-bar {
      background: ${({ theme }) => theme.colors.defaultProgressBarBackground};
    }
    &::-webkit-progress-value {
      ${progressValueStyles};
    }
    &::-moz-progress-bar {
      ${progressValueStyles};
    }
    &::-ms-fill {
      ${progressValueStyles};
    }
  `,
}
