import styled from 'styled-components'

export const StyledLanguageSelector = {
  Wrapper: styled.div`
    position: fixed;
    top: 7px;
    inset-inline-start: 7px;
    z-index: 101;
  `,
}
