import styled from 'styled-components'

import { MediaBreakpoint } from 'root-constants/common'

export const StyledSearchNumberAnimationVariant2 = {
  Wrapper: styled.div`
    margin-top: 40px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-top: 0;
    }
  `,
  AnimationContainer: styled.div`
    width: 100%;
    max-height: 280px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      height: 485px;
      width: 565px;
      max-height: 100%;
    }
  `,
}
