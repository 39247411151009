import styled from 'styled-components'

import { Button } from 'components/Button'
import { SvgImage } from 'components/SvgImage'

import {
  baseAdaptiveColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import {
  BASE_COLUMN_WIDTH,
  HEADER_HEIGHT,
  MediaBreakpoint,
} from 'root-constants/common'

type TContentProps = {
  hasPhoneLabel: boolean
}

type TButtonProps = {
  isChinese?: boolean
}

export const StyledEmailVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div<TContentProps>`
    ${baseAdaptiveColumnStyles};
    height: ${({ hasPhoneLabel }) =>
      hasPhoneLabel ? 'calc(100dvh - 116px)' : 'calc(100dvh - 56px)'};
    padding: 23px 0 17px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      justify-content: flex-end;
      padding: 23px 30px 17px;
    }
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    margin: 0 0 12px;
    color: ${({ theme }) => theme.colors.primaryText};
    font-weight: ${({ theme }) => theme.locales?.chineseTextFontWeight || 700};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: block;
      margin: 0 -30px 48px;
      font-weight: 800;
      font-size: 48px;
      line-height: 65px;
    }
  `,
  Subtitle: styled.h3`
    margin-bottom: 17px;
    text-align: center;
    font-size: ${({ theme }) => theme.common.subtitleFontSize};
    font-weight: 600;
    line-height: normal;
    color: ${({ theme }) => theme.colors.secondaryText};
  `,
  InputWrapper: styled.div`
    width: 100%;
    max-width: 310px;
    position: relative;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 460px;
    }
  `,
  AutoCompleteWrapper: styled.div`
    width: 100%;
    max-height: 160px;
    padding: 0 15px;
    position: absolute;
    top: 56px;
    left: 0;
    border-radius: 12px;
    overflow-y: auto;
    background: ${({ theme }) => theme.colors.emailTipsBackground};
    filter: ${({ theme }) => theme.common.filter};
    z-index: 2;
    transform: translateZ(0);
  `,
  AutoCompleteButton: styled.button`
    height: 50px;
    width: 100%;
    padding: 0;
    display: block;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 50px;
    text-decoration: none;
    text-align: left;
    color: ${({ theme }) => theme.colors.primaryText};
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    direction: rtl;

    :not(:last-child) {
      border-bottom: ${({ theme }) => theme.common.autoCompleteButtonBorder};
    }
  `,
  AutoCompleteButtonTextWrapper: styled.span`
    unicode-bidi: plaintext;
  `,
  Note: styled.div`
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    margin-bottom: 20px;
    max-width: 310px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 460px;
      margin-bottom: 232px;
    }
  `,
  LockImg: styled(SvgImage)`
    width: 100%;
    max-width: 15px;
    margin-left: 3px;
    fill: ${({ theme }) => theme.colors.secondaryText};
  `,
  Description: styled.p`
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.secondaryText};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 16px;
      line-height: 22px;
    }
  `,
  Button: styled(Button)<TButtonProps>`
    margin: 0 0 22px;
    font-size: ${({ isChinese }) => (isChinese ? 25 : 20)}px;
    font-weight: ${({ isChinese }) => (isChinese ? 500 : 700)};

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      width: 460px;
      height: 76px;
      font-size: 24px;
      border-radius: 100px;
      margin: 0 auto 24px;
    }
  `,
  Footer: styled.footer`
    max-width: ${BASE_COLUMN_WIDTH}px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 510px;
    }
  `,
}
