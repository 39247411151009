import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { DesktopProvider } from 'components/DesktopProvider'

import socialProofImageMobile from 'assets/images/social-proof-5-mobile.webp'
import socialProofImageDesktop from 'assets/images/social-proof-5.jpg'

import { StyledSocialProof as S } from './SocialProof.styles'
import { PAGE_NAMES } from './constants'

export const SocialProofVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAMES[pageId],
    question: '',
    nextPagePath,
  })

  return (
    <DesktopProvider imageSrc={socialProofImageDesktop}>
      <S.Wrapper>
        <S.Image src={socialProofImageMobile} alt="users" />
        <S.Column>
          <S.Description>
            <Trans
              i18nKey="onboarding.socialProof.variant2.paragraph"
              components={{ strong: <strong />, b: <b />, br: <br /> }}
            />
          </S.Description>
          <S.Button onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </S.Button>
        </S.Column>
      </S.Wrapper>
    </DesktopProvider>
  )
}
