import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

import {
  selectCurrentVariantCohort,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useUserStatus } from 'hooks/user/useUserStatus'

import { getPathFromPageId } from 'helpers/getPathFromPageId'

interface IProps {
  isAvailable: boolean
  children: React.ReactElement
}

export const ProtectedRoute: React.FC<IProps> = ({ isAvailable, children }) => {
  const { search, hash } = useLocation()
  const availableRoute = useUserStatus()
  const uuid = useSelector(selectUUID)
  const cohort = useSelector(selectCurrentVariantCohort)
  const language = useSelector(selectLanguage)

  const pathToRedirect = useMemo(
    () =>
      getPathFromPageId({
        pageId: availableRoute,
        cohort,
        uuid,
        language,
        currentSearch: search,
        hash,
      }),
    [availableRoute, cohort, uuid, language, search, hash],
  )

  return isAvailable ? children : <Navigate to={`/${pathToRedirect}`} />
}
