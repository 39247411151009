import styled, { css } from 'styled-components'

import { BurgerMenu } from 'components/BurgerMenu'
import { Button } from 'components/Button'
import { SvgImage } from 'components/SvgImage'

import formBg from 'assets/images/form-bg.png'
import gpsIcon from 'assets/images/gps-icon.svg'

import {
  StyledPhoneInput,
  baseColumnStyles,
  basePageStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

type TProps = {
  marginTop?: number
  marginBottom?: number
}
type TStepsSubtitleProps = {
  isLanguageDe?: boolean
}
type TButtonProps = {
  backgroundDirection: string
}
type TTitleProps = {
  hasOverSizeFont: boolean
}

const titleParams = css`
  font-size: 24px;
  font-weight: ${({ theme }) => theme.locales?.chineseTextFontWeight || 800};
  line-height: 30px;
  text-align: center;
`

const subtitleParams = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`

export const StyledIntroGoogleVariant4 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    padding-bottom: 25px;
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 6px;
      inset-inline-end: 6px;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        top: 3px;
        inset-inline-end: 3px;
      }
    }
  `,
  Title: styled.h1<TTitleProps>`
    color: #0a324d;
    font-size: ${({ hasOverSizeFont }) => (hasOverSizeFont ? 24 : 32)}px;
    font-weight: ${({ theme }) => theme.locales?.chineseTextFontWeight || 800};
    line-height: 38px;
    text-align: center;
    margin-top: 24px;
    margin-bottom: ${({ hasOverSizeFont }) => (hasOverSizeFont ? 10 : 0)}px;

    strong {
      font-size: 32px;
      font-weight: 800;
      line-height: 38px;
      color: ${Color.BLUE};
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    width: 100%;
    background-image: url(${formBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  `,
  TrackerWrapper: styled.div`
    display: flex;
    margin: 32px auto;
    text-align: center;
    direction: ltr;

    p {
      margin: 0 10px;
      font-size: 20px;
      font-weight: ${({ theme }) =>
        theme.locales?.chineseTextFontWeight || 800};
      line-height: 22px;
    }

    strong {
      font-size: 32px;
      line-height: 32px;
    }

    img {
      width: 46px;
      height: 87px;
    }
  `,
  PhoneForm: styled.form`
    width: 343px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  `,
  PhoneText: styled.h3`
    text-align: center;
    font-size: 20px;
    font-weight: ${({ theme }) => theme.locales?.chineseTextFontWeight || 600};
    line-height: 27px;
    width: 296px;
  `,
  PhoneNumberInput: styled(StyledPhoneInput)`
    border: 1px solid #cbc3e7;
  `,
  PhoneErrorText: styled.p`
    color: #ff0000;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  `,
  Text: styled.p`
    ${subtitleParams};
    text-align: center;
    max-width: 247px;
  `,
  Subtitle: styled.h2<TProps>`
    ${titleParams};
    margin-top: ${({ marginTop }) => marginTop || 48}px;
    margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;

    strong {
      ${titleParams};
      color: ${Color.BLUE};
    }
  `,
  ReviewsSubtitle: styled.h3`
    color: #0a324d;
    text-align: center;
    ${titleParams};
    line-height: 28px;
    margin-top: 52px;
    margin-bottom: 24px;
  `,
  BenefitsWrapper: styled.div`
    display: flex;
    width: 311px;
    padding: 16px;
    flex-direction: column;
    gap: 18px;
    border-radius: 16px;
    background: linear-gradient(
      266deg,
      rgba(212, 178, 255, 0.15) 44.65%,
      rgba(254, 182, 224, 0.15) 100%
    );
    margin-top: 24px;
    margin-bottom: 48px;
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    gap: 18px;
  `,
  ListItem: styled.li`
    display: grid;
    grid-template-columns: 23px 1fr;
    grid-template-rows: auto;
    grid-gap: 2px 12px;
    grid-template-areas: 'icon title' 'icon text';
  `,
  ListTitle: styled.h3`
    grid-area: title;
    color: #0a324d;
    font-size: 18px;
    font-weight: ${({ theme }) => theme.locales?.chineseTextFontWeight || 700};
    line-height: 25px;
  `,
  ListIcon: styled(SvgImage)`
    grid-area: icon;
  `,
  ListText: styled.p`
    grid-area: text;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  `,
  SwiperContainer: styled.div`
    flex-grow: 1;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    aspect-ratio: 430/350;
    width: 100%;

    .swiper-wrapper {
      display: flex;
      align-items: center;
      padding-bottom: 38px;
    }

    .swiper-slide {
      max-width: 154px;

      img {
        width: 100%;
        max-width: 154px;
      }
    }

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      background-color: #b2b8cd;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background-color: ${Color.ACCENT_VIOLET};
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  PossibilitiesWrapper: styled.div`
    width: 100%;
    padding: 24px 0;
    margin: 48px 0 0;
    background: linear-gradient(
      179deg,
      rgba(246, 249, 255, 0.8) 26.53%,
      rgba(249, 244, 255, 0.8) 99.42%
    );

    img {
      width: 100%;
      max-width: 85px;
      display: inline-block;
    }
  `,
  PossibilitiesTitle: styled.h2`
    ${titleParams};
    margin: 0 auto 24px;
    max-width: 327px;
  `,
  PossibilitiesContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    text-align: center;
  `,
  PossibilitiesIconContainer: styled.div`
    aspect-ratio: 85/64;
    width: 100%;
    max-width: 85px;
    margin: 0 auto;
  `,
  ContainerTitle: styled.h3`
    font-size: 20px;
    font-weight: ${({ theme }) => theme.locales?.chineseTextFontWeight || 700};
    line-height: 27px;
    margin-bottom: 8px;
    margin-top: 16px;
    max-width: 311px;
  `,
  ContainerSubtitle: styled.p`
    ${subtitleParams};
    max-width: 311px;
  `,
  StepsWrapper: styled.div`
    display: flex;
    gap: 24px;
    margin-top: 24px;

    img {
      max-height: 342px;
      margin-top: 9px;
    }
  `,
  StepsContent: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 275px;
  `,
  StepsTitle: styled.h4`
    padding: 4px 12px;
    border-radius: 16px;
    margin-bottom: 12px;
    background-color: #eff5f7;
    ${subtitleParams};
    font-weight: ${({ theme }) => theme.locales?.chineseTextFontWeight || 700};
    text-transform: uppercase;
    width: fit-content;
  `,
  StepsSubtitle: styled.p<TStepsSubtitleProps>`
    ${subtitleParams};
    font-size: ${({ isLanguageDe }) => (isLanguageDe ? 15 : 16)}px;
  `,
  BottomPhoneForm: styled.form`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    background: linear-gradient(122deg, #f0f5ff 7.62%, #f0e4ff 95.27%);
    padding: 24px 0;
    margin: 48px 0 8px;
  `,
  Button: styled(Button)<TButtonProps>`
    background-image: url(${gpsIcon});
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: ${({ backgroundDirection }) => backgroundDirection};
  `,
  SecurityWrapper: styled.div`
    width: 311px;
    margin-top: 40px;
  `,
}
