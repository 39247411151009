import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import lottie, { AnimationItem } from 'lottie-web/build/player/lottie_light'
import { EffectCreative, Pagination, Swiper } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { SvgImage } from 'components/SvgImage'

import animationData from 'assets/animations/my-love.json'
import arrow from 'assets/images/sprite/right-arrow.svg'

import { StyledBenefitsVariant2 as S } from './BenefitsVariant2.styles'
import { ANIMATION_SEGMENTS, BENEFITS_V2 } from './constants'

export const BenefitsVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const swiperRef = useRef<Swiper | null>(null)
  const animationRef = useRef<HTMLDivElement>(null)

  const [myLoveAnimation, setMyLoveAnimation] = useState<AnimationItem | null>(
    null,
  )
  const [isReachedEnd, setIsReachedEnd] = useState(false)

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  useEffect(() => {
    if (animationRef.current) {
      const myLoveAnimationInstance = lottie.loadAnimation({
        name: 'myLove',
        container: animationRef.current,
        animationData,
        initialSegment: ANIMATION_SEGMENTS[0],
        loop: false,
      })

      setMyLoveAnimation(myLoveAnimationInstance)
    }

    return () => lottie.destroy('myLove')
  }, [])

  const handleButtonClick = () => {
    if (isReachedEnd) {
      handleContinue('')
      return
    }

    swiperRef.current?.slideNext()
    myLoveAnimation?.playSegments(
      ANIMATION_SEGMENTS[swiperRef.current?.activeIndex || 0],
      true,
    )
  }

  return (
    <S.Wrapper>
      <S.BlackBackground />
      <S.AnimationContainer ref={animationRef} />

      <S.Content>
        <S.SwiperContainer>
          <Carousel
            slidesPerView={1}
            centeredSlides
            modules={[Pagination, EffectCreative]}
            pagination={{ clickable: false }}
            allowTouchMove={false}
            onBeforeInit={(swiper: Swiper) => {
              swiperRef.current = swiper
            }}
            onReachEnd={() => setIsReachedEnd(true)}
            effect="creative"
            creativeEffect={{
              limitProgress: 2,
              prev: {
                scale: 0.86,
                translate: ['-100%', 0, 0],
              },
              next: {
                scale: 0.86,
                translate: ['100%', 0, 0],
              },
            }}
          >
            {BENEFITS_V2.map(({ title, subtitle }) => (
              <SwiperSlide key={title}>
                <S.Title>{t(title)}</S.Title>
                <S.Subtitle>{t(subtitle)}</S.Subtitle>
              </SwiperSlide>
            ))}
          </Carousel>
        </S.SwiperContainer>

        <S.Button onClick={handleButtonClick}>
          {t`actions.continue`}
          <SvgImage svg={arrow} width={24} />
        </S.Button>
      </S.Content>
    </S.Wrapper>
  )
}
