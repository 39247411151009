import styled from 'styled-components'

import { MediaBreakpoint } from 'root-constants/common'

type TSecureItemTextProps = {
  hasOverSizeFont: boolean
}

export const StyledSecurity = {
  SecureImage: styled.img`
    margin: 0 auto 26px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin: 0 auto 40px;
    }
  `,
  SecureCard: styled.div`
    display: flex;
    flex-direction: column;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 32px;
    padding: 22px 24px 0;
    background-color: ${({ theme }) => theme.colors.guaranteeBackground};
    border-radius: 16px;

    li {
      margin-bottom: 32px;
    }

    a {
      color: #0091ff;
      font-weight: 700;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      padding: 24px 40px 40px;
      min-width: 1080px;

      ul {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
        gap: 52px;
      }

      li {
        margin-bottom: 0;
      }
    }
  `,
  SecureItemTitle: styled.h4`
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 22px;
    font-weight: ${({ theme }) => theme.locales?.chineseTextFontWeight || 700};
    color: ${({ theme }) => theme.colors.primaryText};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 20px;
      line-height: 22px;
    }
  `,
  SecureItemText: styled.p<TSecureItemTextProps>`
    color: ${({ theme }) => theme.colors.tertiaryText};

    a {
      display: inline-block;
      font-weight: ${({ theme }) =>
        theme.locales?.chineseTextFontWeight || 700};

      ::first-letter {
        text-transform: uppercase;
      }
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: ${({ hasOverSizeFont }) => (hasOverSizeFont ? 15 : 14)}px;
      line-height: 20px;
      max-width: 298px;
    }
  `,
}
