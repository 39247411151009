import styled, { css } from 'styled-components'

import { PaymentRequestButton } from 'modules/payment/components/PaymentRequestButton'
import { StripePaymentForm } from 'modules/payment/components/StripePaymentForm'

import { PhoneNumberLabel } from 'components/PhoneNumberLabel'

import { Color, MediaBreakpoint } from 'root-constants/common'

type TTextProps = {
  gridArea: string
}

const commonPriceTextStyles = css`
  color: #91bfff;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`

export const StyledPaymentFlowsGoogleVariant4 = {
  Wrapper: styled.div``,
  PhoneNumberLabel: styled(PhoneNumberLabel)`
    padding: 8px 16px;
    border-radius: 0 0 20px 20px;
    background: linear-gradient(90deg, #fc8e57 0.61%, #ffd651 59.15%);

    p:first-of-type {
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      color: #35319f;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        font-size: 17px;
        line-height: 24px;
      }
    }

    p:last-of-type {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: #35319f;

      strong {
        font-weight: 700;
      }

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
  `,
  PriceContainer: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'priceText priceValue'
      'trialDuration trialDuration';
    margin: 24px 0 16px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin: 32px 0 24px;
    }
  `,
  PaymentRequestButton: styled(PaymentRequestButton)`
    margin-bottom: 24px;
  `,
  PriceText: styled.p<TTextProps>`
    ${commonPriceTextStyles};
    grid-area: ${({ gridArea }) => gridArea};
    justify-self: flex-start;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 32px;
      line-height: 40px;
    }
  `,
  PriceValue: styled.p<TTextProps>`
    ${commonPriceTextStyles};
    grid-area: ${({ gridArea }) => gridArea};
    justify-self: flex-end;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 32px;
      line-height: 40px;
    }
  `,
  TrialDurationText: styled.p`
    grid-area: trialDuration;
    justify-self: flex-end;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: ${Color.WHITE};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 15px;
      line-height: 20px;
    }
  `,
  StripePaymentForm: styled(StripePaymentForm)`
    input {
      margin-bottom: 24px;
      font-size: 17px;
      color: #616a77;
    }

    p {
      font-size: 13px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.5px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: initial;
      margin-bottom: 25px;
    }
  `,
  Image: styled.img`
    max-width: 293px;
    margin: 32px auto 16px;
  `,
  Guarantee: styled.p`
    color: ${Color.WHITE};
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding-bottom: 50px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
    }
  `,
  Disclaimer: styled.p`
    margin-bottom: 32px;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    color: #dfecff;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 13px;
      line-height: 16px;
    }
  `,
}
