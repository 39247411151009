import React, { ReactNode } from 'react'

import { t } from 'i18next'

import { getCurrentEnv } from 'helpers/getCurrentEnv'

import { eventLogger } from 'services/eventLogger.service'

import { CONTACT_FORM_LINKS, CurrentEnvironment } from 'root-constants/common'

type TProps = {
  children?: ReactNode
  className?: string
}

export const SupportLink: React.FC<TProps> = ({
  children = t('actions.contactSupport'),
  className,
}) => {
  const currentEnv = getCurrentEnv()

  const handleClick = () => {
    eventLogger.logNeedHelpClicked()
  }

  const link = CONTACT_FORM_LINKS[currentEnv || CurrentEnvironment.STAGE]

  return (
    <a
      href={link}
      className={className}
      target="_blank"
      rel="noreferrer"
      onClick={handleClick}
    >
      {children}
    </a>
  )
}
