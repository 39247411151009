import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectTaxAmount } from 'root-redux/selects/common'

import { useVatInfo } from 'hooks/common/useVatInfo'

import { getPriceWithoutTaxes } from 'helpers/getPriceWithoutTaxes'

import { ISubscription } from 'models/subscriptions.model'

import { BillingCycle, CURRENCY_SYMBOLS } from 'root-constants/common'

import { StyledPlanItemVariant1 as S } from './PlanItemVariant1.styles'
import { PERIOD_NAMES, PERIOD_QUANTITIES } from './constants'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const PlanItemVariant1: React.FC<TProps> = ({
  subscription: {
    currency,
    mainPrices: { periodQuantity },
    trialPrices: { daily },
  },
  isSelected,
}) => {
  const { t } = useTranslation()
  const taxAmount = useSelector(selectTaxAmount)

  const hasIncludedVat = useVatInfo()

  const dailyPriceWithoutTaxes = getPriceWithoutTaxes({
    price: daily,
    taxAmount,
  })

  return (
    <S.Wrapper isSelected={isSelected}>
      <S.Period>
        <Trans
          i18nKey="subscriptions.planBlock.planTitle2"
          values={{
            periodQuantity: PERIOD_QUANTITIES[periodQuantity],
            periodName: t(PERIOD_NAMES[periodQuantity], {
              count: periodQuantity,
            }),
          }}
        />
      </S.Period>
      <S.Price isSelected={isSelected}>
        {t('payment.variant4.pricePerDay', {
          currencySymbol: CURRENCY_SYMBOLS[currency],
          price: hasIncludedVat ? dailyPriceWithoutTaxes : daily,
        })}
      </S.Price>
      {isSelected && periodQuantity === BillingCycle.ONE_MONTH && (
        <S.Text>{t`payment.variant4.noCommitment`}</S.Text>
      )}
      {isSelected && <S.Checkbox />}
    </S.Wrapper>
  )
}
