import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { resetErrorAction } from 'root-redux/actions/common'
import { getUserStatusAction } from 'root-redux/actions/user'
import {
  selectActionList,
  selectError,
  selectLanguage,
  selectScreenName,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { ScreenName } from 'services/eventLogger.service'

import {
  CHECK_3D_SECURE,
  MAKE_UPSELL,
  PURCHASE,
} from 'modules/payment/redux/actions/common'
import { PURCHASE_PRIMER } from 'modules/payment/redux/actions/primer'

import { Spinner } from 'components/Spinner'

import errorIcon from 'assets/images/error.svg'

import { goTo } from 'browser-history'
import { StyledPaymentStatus as S } from 'common-styles'
import { Language } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { PaymentSuccess } from '../PaymentSuccess'

type TProps = {
  onTryAgain?: () => void
  onNextStep?: () => void
  hasChipolo?: boolean
}

export const StripePaymentProcessing: React.FC<TProps> = ({
  onTryAgain,
  onNextStep,
  hasChipolo = false,
  ...props
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const fetchingActionsList = useSelector(selectActionList)
  const screenName = useSelector(selectScreenName)
  const requestError = useSelector(selectError)
  const uuid = useSelector(selectUUID)
  const language = useSelector(selectLanguage)

  const [isShown, setIsShown] = useState(false)

  const isPurchaseInProcess = fetchingActionsList.some((action) =>
    [PURCHASE, PURCHASE_PRIMER, CHECK_3D_SECURE, MAKE_UPSELL].includes(action),
  )

  const isUpgradeScreenName = screenName === ScreenName.UPGRADE

  useEffect(() => {
    return () => {
      dispatch(resetErrorAction())
    }
  }, [dispatch])

  useEffect(() => {
    if (isPurchaseInProcess) {
      setIsShown(true)
    }
  }, [isPurchaseInProcess])

  useEffect(() => {
    window.ononline = () => {
      dispatch(getUserStatusAction(uuid))
    }
  }, [dispatch, uuid])

  const handleResetError = () => {
    if (!window.navigator.onLine) return

    dispatch(getUserStatusAction(uuid))
    setIsShown(false)
    dispatch(resetErrorAction())

    onTryAgain && onTryAgain()
  }

  const handleClose = () => {
    dispatch(resetErrorAction())
    goTo({ pathname: PageId.ACCOUNT, search })
  }

  return (
    <>
      {isPurchaseInProcess && (
        <S.Wrapper {...props}>
          <S.Container>
            <Spinner isFullScreen={false} />
            <S.Title>{t`payment.stripe.titleProcessing`}</S.Title>
            <S.Subtitle>{t`payment.stripe.subtitleProcessing`}</S.Subtitle>
          </S.Container>
        </S.Wrapper>
      )}
      {!isPurchaseInProcess && !requestError && isShown && (
        <PaymentSuccess
          onNextStep={onNextStep}
          hasChipolo={hasChipolo}
          {...props}
        />
      )}
      {!isPurchaseInProcess && requestError && isShown && (
        <S.Wrapper {...props}>
          <S.Container data-testid="purchase-outcome-container">
            {isUpgradeScreenName && <S.CloseButton onClick={handleClose} />}
            <S.Icon src={errorIcon} alt="Error" />
            <S.Title>{t`payment.stripe.error`}</S.Title>
            <S.Subtitle>
              {requestError?.description ||
                requestError ||
                t`payment.commonError`}
            </S.Subtitle>
            <S.Button
              onClick={handleResetError}
              isPtLanguage={language === Language.PT}
            >
              {t`payment.stripe.tryAgain`}
            </S.Button>
          </S.Container>
        </S.Wrapper>
      )}
    </>
  )
}
