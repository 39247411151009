import { useLocation } from 'react-router-dom'

import { convertSnakeCaseToReadableText } from 'helpers/convertSnakeCaseToReadableText'

const HEADLINE_QUERY_PARAM = 'headline'

export const useURLTitle = () => {
  const { search } = useLocation()

  const urlSearchParams = new URLSearchParams(search)
  const urlParams = Object.fromEntries(urlSearchParams.entries())

  return convertSnakeCaseToReadableText(urlParams[HEADLINE_QUERY_PARAM] || '')
}
