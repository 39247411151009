import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { GEOLOCATION_BENEFITS } from 'pages/intro-google/components/GeolocationBenefits/constants'

import { Language } from 'root-constants/common'

import { StyledGeolocationBenefitsVariant1 as S } from './GeolocationBenefitsVariant1.styles'

export const GeolocationBenefitsVariant1: React.FC = () => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const hasOverSizeFont = language === Language.PT || language === Language.ES

  return (
    <S.Wrapper>
      <S.Title>{t`onboarding.introGoogle5.geolocationBenefits.title`}</S.Title>
      <S.BenefitsContainer>
        {GEOLOCATION_BENEFITS.map(({ img, title, text, borderColor }) => (
          <S.BenefitsWrapper key={title} borderColor={borderColor}>
            <S.Image src={img} alt="icon" />
            <S.BenefitsTitle>{t(title)}</S.BenefitsTitle>
            <S.Text hasOverSizeFont={hasOverSizeFont}>{t(text)}</S.Text>
          </S.BenefitsWrapper>
        ))}
      </S.BenefitsContainer>
    </S.Wrapper>
  )
}
