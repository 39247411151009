import React, { HTMLAttributes, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Swiper, SwiperSlide } from 'swiper/react'

import { useUserData } from 'hooks/user/useUserData'

import { UserGoal } from 'root-constants/common'

import { StyledReviewsSlider as S } from './ReviewsSlider.styles'
import { REVIEWS_DATA } from './constants'

export interface ISliderProps extends HTMLAttributes<HTMLDivElement> {
  marginBottom?: number
  hasLaptopStyles?: boolean
  goal?: string
}

export const ReviewsSlider: React.FC<ISliderProps> = ({
  marginBottom = 24,
  hasLaptopStyles = false,
  goal,
  ...props
}) => {
  const { t } = useTranslation()
  const { singleUserGoal, userGoalsFormatted, isTeenPath } = useUserData()

  const currentReviewsData = useMemo(() => {
    if (goal) {
      return REVIEWS_DATA[goal]
    }
    if (userGoalsFormatted.length > 1) {
      return REVIEWS_DATA.severalGoalsReviews
    }

    if (singleUserGoal === UserGoal.FRIEND) {
      return REVIEWS_DATA.friendGoalReviews
    }
    if (isTeenPath) {
      return REVIEWS_DATA.teenGoalReviews
    }
    if (singleUserGoal === UserGoal.KID) {
      return REVIEWS_DATA.kidGoalReviews
    }
    return REVIEWS_DATA.default
  }, [goal, singleUserGoal, userGoalsFormatted.length, isTeenPath])

  return (
    <S.Wrapper
      marginBottom={marginBottom}
      data-has-laptop-styles={hasLaptopStyles}
      {...props}
    >
      <Swiper
        slidesPerView="auto"
        spaceBetween={20}
        centeredSlides
        breakpoints={{
          1200: {
            spaceBetween: 12,
            initialSlide: 1,
          },
        }}
      >
        {currentReviewsData.map(
          ({ userName, reviewText, avatar, borderColor }) => (
            <SwiperSlide key={userName}>
              <S.Card
                borderColor={borderColor}
                data-has-laptop-styles={hasLaptopStyles}
              >
                <S.Avatar width="66" height="66" src={avatar} loading="lazy" />
                <S.CardTitle>{t(userName)}</S.CardTitle>
                <S.Recommendation
                  text={t`subscriptions.reviewsSlider.recommendation`}
                />
                <S.CardText>{t(reviewText)}</S.CardText>
              </S.Card>
            </SwiperSlide>
          ),
        )}
      </Swiper>
    </S.Wrapper>
  )
}
