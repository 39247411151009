import styled from 'styled-components'

import { SelectPlanBlockVariant1 } from 'modules/subscriptions/components/common/SelectPlanBlockVariant1'
import { Timer } from 'modules/subscriptions/components/common/Timer'

import { Button } from 'components/Button'
import { ReviewsSlider2 } from 'components/ReviewsSlider'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledSubscriptionsVariant6 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100%;
    padding-top: ${HEADER_HEIGHT}px;
    overflow-x: hidden;
    padding-bottom: 40px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
  `,
  Timer: styled(Timer)`
    margin-top: 16px;
  `,
  Title: styled.h1`
    margin: 24px 0;
    font-size: 30px;
    line-height: 41px;
    font-weight: 800;
    text-align: center;
  `,
  Subtitle: styled.h2`
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  `,
  ImageWrapper: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    aspect-ratio: 375/302;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  SubscriptionsTitle: styled.h2`
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  `,
  Button: styled(Button)`
    height: 56px;
    line-height: 22px;
    margin: 16px 0 48px;
    text-transform: capitalize;
  `,
  PlanBlockWrapper: styled.div`
    width: 100%;
  `,
  SelectPlanBlock: styled(SelectPlanBlockVariant1)`
    margin-bottom: 12px;
  `,
  ReviewSlider: styled(ReviewsSlider2)`
    .swiper-slide > div {
      min-height: 190px;
    }
  `,
  HowItWorksTitle: styled.h2`
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;
    margin-top: 24px;
  `,
}
