import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'
import { useUserData } from 'hooks/user/useUserData'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { FixedContainer } from 'components/FixedContainer'

import parentsImage from 'assets/images/parents.jpg'

import { StyledParentReviewsVariant1 as S } from './ParentReviewsVariant1.styles'
import { REVIEWS_DATA } from './constants'

export const ParentReviewsVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { childFlow } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Content>
        <S.ImageContainer>
          <img src={parentsImage} alt="parents" />
        </S.ImageContainer>
        <S.Title>{t`onboarding.parentReviews.title`}</S.Title>
        <S.ReviewSlider reviews={REVIEWS_DATA[childFlow]} />

        <FixedContainer>
          <Button onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </Button>
        </FixedContainer>
      </S.Content>
    </S.Wrapper>
  )
}
