import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getUserStatusAction } from 'root-redux/actions/user'

import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'
import { useGetPrices } from 'hooks/common/useGetPrices'
import { usePaywall } from 'hooks/common/usePaywall'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useVatInfo } from 'hooks/common/useVatInfo'
import { useUserStatus } from 'hooks/user/useUserStatus'

import { ISubscription } from 'models/subscriptions.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PlanItemVariant1 } from 'modules/payment/components/PlanItem'
import { SelectPaymentMethod } from 'modules/payment/components/SelectPaymentMethod'
import { SelectPlanBlockVariant1 } from 'modules/payment/components/SelectPlanBlock'
import { StripePaymentFormVariant2 } from 'modules/payment/components/StripePaymentForm'
import { PaymentMethod } from 'modules/payment/constants'
import { setPaymentMethodAction } from 'modules/payment/redux/actions/common'

import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'
import { SupportLink } from 'components/SupportLink'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import phoneImage from 'assets/images/payment-phone.png'
import arrowDown from 'assets/images/sprite/arrow-down-violet.svg'

import { goTo } from 'browser-history'
import { SubscriptionTag } from 'root-constants/common'

import {
  BackgroundGlobalStyles,
  StyledPaymentVariant4 as S,
} from './PaymentVariant4.styles'
import { BILLING_CYCLE_CONTEXTS, PAYMENT_METHOD_INFO } from './constants'

export const PaymentVariant4: React.FC = () => {
  const { search } = useLocation()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isPaymentMethodPopupShown, setIsPaymentMethodPopupShown] =
    useState(false)

  const {
    email,
    uuid,
    subscriptions,
    selectedSubscription,
    selectedSubscriptionId,
    trialPrice,
    currentPrice,
    periodQuantity,
    isPaymentFlowsShown,
    paymentMethod,
    paymentRequestButtonType,
    hasSubscription,
    stripeAccountName,
    stripeAccountId,
    threeDSecureIframeUrl,
  } = usePurchaseStore()

  const { hasPrices } = usePaywall('')
  const hasIncludedVAT = useVatInfo()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()
  const availableRoute = useUserStatus()

  useGetPrices({
    tags: hasIncludedVAT ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX,
  })

  const [integerTrialPrice, fractionalTrialPrice = ''] = trialPrice
    .toFixed(2)
    .split('.')

  const [integerFullPrice, fractionalFullPrice = ''] = currentPrice
    .toFixed(2)
    .split('.')

  useLayoutEffect(() => {
    if (hasSubscription) {
      goTo({ pathname: availableRoute, search })
    }
  }, [availableRoute, hasSubscription, search])

  useEffect(() => {
    if (!paymentRequestButtonType) return
    dispatch(setPaymentMethodAction(paymentRequestButtonType))
  }, [dispatch, paymentRequestButtonType])

  useEffect(() => {
    if (!subscriptions.length) return

    window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
    googleAnalyticsLogger.logCheckoutStarted(subscriptions)
  }, [subscriptions, uuid])

  useEffect(() => {
    eventLogger.logPurchaseShown({
      screenName: ScreenName.ONBOARDING,
      stripeAccountName,
      stripeAccountId,
    })
  }, [stripeAccountId, stripeAccountName])

  const sendEvents = () => {
    window.fbq('track', 'AddToCart', {}, { eventID: uuid })
    window.snaptr &&
      window.snaptr('track', 'ADD_CART', {
        user_email: isPersonalDataAllowed ? email : '',
      })
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq.track('AddToCart')
    googleAnalyticsLogger.logAddingToCart(selectedSubscription as ISubscription)
  }
  const togglePopupVisibility = () => {
    setIsPaymentMethodPopupShown((prev) => !prev)
  }

  const handlePaymentMethodClick = () => {
    eventLogger.logPaymentMethodTap()
    togglePopupVisibility()
  }

  const handlePaymentMethodChange = ({ target: { value } }) => {
    eventLogger.logPaymentMethodSelected({ paymentMethod: value })
    dispatch(setPaymentMethodAction(value))
    togglePopupVisibility()
  }

  const handleContinue = async () => {
    await dispatch(getUserStatusAction(uuid))
    goTo({
      pathname: availableRoute,
      search,
    })
  }

  return (
    <S.Wrapper>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
      ) : (
        <>
          <BackgroundGlobalStyles />
          <S.BurgerMenu />
          <StripePaymentProcessing onNextStep={handleContinue} />

          <S.Content>
            <S.Title>
              <Trans i18nKey="payment.variant4.title" />
            </S.Title>

            <S.BenefitsContainer>
              <S.BenefitsText>{t`payment.variant4.realTimeLocation`}</S.BenefitsText>
              <S.BenefitsText>{t`payment.variant4.locationHistory`}</S.BenefitsText>
              <S.BenefitsText>{t`payment.variant4.sos`}</S.BenefitsText>
              <S.BenefitsImage src={phoneImage} alt="phone" />
            </S.BenefitsContainer>

            <SelectPlanBlockVariant1 SelectPlanItem={PlanItemVariant1} />

            <S.PaymentMethod>
              <S.PaymentMethodName>
                <SvgImage
                  svg={PAYMENT_METHOD_INFO[paymentMethod].icon}
                  width={PAYMENT_METHOD_INFO[paymentMethod].iconWidth}
                />
                {t(PAYMENT_METHOD_INFO[paymentMethod].name)}
              </S.PaymentMethodName>
              {!!paymentRequestButtonType && (
                <S.SwitchPaymentMethod onClick={handlePaymentMethodClick}>
                  {t`payment.variant4.changeMethod`}
                  <SvgImage svg={arrowDown} width={14} />
                </S.SwitchPaymentMethod>
              )}
            </S.PaymentMethod>

            <S.PaymentRequestButtonContainer
              isVisible={paymentMethod !== PaymentMethod.CREDIT_CARD}
            >
              <S.PaymentRequestButton
                key={selectedSubscriptionId}
                buttonHeight={62}
                onSendEvents={sendEvents}
              />
              <S.Button type="button">{t`actions.continue`}</S.Button>
            </S.PaymentRequestButtonContainer>

            {paymentMethod === PaymentMethod.CREDIT_CARD && (
              <StripePaymentFormVariant2 onSendEvents={sendEvents} />
            )}

            <S.Disclaimer>
              <Trans
                i18nKey="payment.variant4.disclaimer"
                values={{
                  integerTrial: integerTrialPrice,
                  fractionalTrial: fractionalTrialPrice,
                  integerFull: integerFullPrice,
                  fractionalFull: fractionalFullPrice,
                  vatInfo: hasIncludedVAT ? t('commonComponents.inclVat') : '',
                  context: BILLING_CYCLE_CONTEXTS[periodQuantity],
                }}
                components={{
                  termsOfUse: <TermsOfUseLink />,
                  supportLink: <SupportLink />,
                }}
              />
            </S.Disclaimer>

            {isPaymentMethodPopupShown && (
              <SelectPaymentMethod
                onClose={togglePopupVisibility}
                onChange={handlePaymentMethodChange}
              />
            )}
          </S.Content>
        </>
      )}
      {(!isPaymentFlowsShown || !hasPrices) && <Spinner />}
    </S.Wrapper>
  )
}
