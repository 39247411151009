import styled from 'styled-components'

import { Divider } from 'modules/payment/components/Divider'

import { MediaBreakpoint } from 'root-constants/common'

export const StyledPaymentRequestButton = {
  Wrapper: styled.div`
    margin-top: 24px;
    width: 100%;

    & * {
      border-radius: 28px;
    }

    &[data-has-laptop-styles='true'] {
      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        width: 310px;
        margin-top: 0;
      }
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 460px;
    }
  `,
  Divider: styled(Divider)`
    margin-bottom: 25px;
  `,
}
