import styled, { css } from 'styled-components'

import { Button } from 'components/Button'
import { Switch } from 'components/Switch'

import minusIcon from 'assets/images/minus-accordion-icon.svg'
import plusIcon from 'assets/images/plus-accordion-icon.svg'

import { Color, MediaBreakpoint } from 'root-constants/common'

type TDescriptionProps = {
  hasUnfullWidth: boolean
  isFamyLocateCohort: boolean
}
type TTitleProps = {
  marginBottom?: number
}
type TWrapperProps = {
  fullHeight: boolean
  hasUnfullWidth: boolean
}
type TButtonProps = {
  hasUnfullWidth: boolean
}

export const StyledCookieConsent = {
  Wrapper: styled.div<TWrapperProps>`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 8px 20px 0 #cccfde;
    background-color: ${Color.WHITE};
    z-index: 3;
    ${({ fullHeight }) => {
      return (
        fullHeight &&
        css`
          height: 100dvh;
          overflow-y: auto;
        `
      )
    }};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      padding: 24px 40px;
      ${({ hasUnfullWidth }) =>
        hasUnfullWidth &&
        `
            width: 760px;
            height: 700px;
            padding: 24px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 24px;
          `}
  `,
  Header: styled.header`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 24px;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #ccdce5;
    padding: 10px 12px;

    img {
      justify-self: center;
      margin-left: 24px;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        display: none;
      }
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      border-bottom: 0;
      padding: 0;
      grid-template-columns: none;
      position: absolute;
      top: 24px;
      right: 24px;
    }
  `,
  CloseButton: styled.button`
    all: unset;
    display: flex;
    align-items: center;
    cursor: pointer;
  `,
  Container: styled.div`
    max-width: 375px;
    margin: 0 auto;
    padding: 16px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 712px;
      padding: 0;
    }
  `,
  SettingsOffContainer: styled.div`
    max-width: 375px;
    margin: 0 auto;
    padding: 16px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: flex;
      align-items: center;
      max-width: 1360px;
      padding: 0;
    }
  `,
  Title: styled.h2<TTitleProps>`
    margin-bottom: ${({ marginBottom }) => `${marginBottom || 8}px`};
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 20px;
      line-height: 27px;
    }
  `,
  Description: styled.p<TDescriptionProps>`
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #536f82;

    a {
      text-decoration: underline;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: #536f82;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        font-size: ${({ isFamyLocateCohort }) =>
          isFamyLocateCohort ? 15 : 16}px;
        line-height: 22px;
      }
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: ${({ isFamyLocateCohort }) =>
        isFamyLocateCohort ? 15 : 16}px;
      line-height: 22px;
      margin-right: 40px;
      ${({ hasUnfullWidth }) =>
        hasUnfullWidth &&
        `
            margin-right: 0;
            margin-top: 24px;
          `}

      }}
  `,
  ButtonsContainer: styled.div`
    padding-top: 16px;
    border-top: 1px solid #ccdce5;
  `,
  PrimaryButton: styled(Button)<TButtonProps>`
    width: 343px;
    height: 38px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    border-radius: 100px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 15px;
      line-height: 22px;
      height: 48px;
      min-width: 180px;
      margin: 0 12px 0 0;

      ${({ hasUnfullWidth }) =>
        hasUnfullWidth &&
        `
            width: 230px;
          `}}
    }
  `,
  SecondaryButton: styled(Button)<TButtonProps>`
    width: 343px;
    height: 38px;
    margin-bottom: 8px;
    background: ${Color.GREY};
    color: ${Color.ACCENT_VIOLET};
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    border-radius: 100px;

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 16px;
      line-height: 22px;
      height: 48px;
      min-width: 180px;
      margin-right: 16px;
      margin-bottom: 0;

      ${({ hasUnfullWidth }) =>
        hasUnfullWidth &&
        `
            width: 230px;
          `}
    }
  `,
  Settings: styled.ul`
    border: 1px solid #ccdce5;
    border-bottom: none;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 24px;
    }
  `,
  SettingItem: styled.li`
    position: relative;

    & label {
      position: absolute;
      right: 16px;
      top: 18px;
    }
  `,
  SettingsTitle: styled.h2`
    margin: 32px auto 20px;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin: 24px auto 20px;
      font-size: 20px;
      line-height: 27px;
    }
  `,
  Setting: styled.details`
    &[open] summary::after {
      background-image: url(${minusIcon});
    }

    &:last-child span {
      flex-basis: 164px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      &:last-child span {
        flex-basis: 546px;
      }
    }
  `,
  SettingName: styled.summary`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
    padding: 12px 16px 12px 41px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    border-bottom: 1px solid #ccdce5;

    &::-webkit-details-marker {
      display: none;
    }

    & span {
      flex-basis: 195px;
    }

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background-image: url(${plusIcon});
      background-size: contain;
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 16px;
      line-height: 22px;

      & span {
        flex-basis: 100%;
      }
    }
  `,
  SettingDetails: styled.p`
    padding: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    background-color: #f9fafb;
    color: #536f82;
    border-bottom: 1px solid #ccdce5;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 16px;
      line-height: 22px;
    }
  `,
  ActiveLabel: styled.strong`
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: ${Color.ACCENT_VIOLET};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 15px;
      line-height: 22px;
    }
  `,
  Divider: styled.div`
    width: 100%;
    height: 1px;
    margin-top: 8px;
    background-color: #ccdce5;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 24px;
      margin-top: 0;
    }
  `,
  Switch: styled(Switch)`
    width: 44px;
    height: 24px;

    div {
      background: #e1e8ec;
    }

    input {
      &:checked ~ div {
        background: ${Color.ACCENT_VIOLET};
        border: 1px solid transparent;
      }
    }

    span {
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
    }
  `,
}
