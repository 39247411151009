import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color, MediaBreakpoint } from 'root-constants/common'

export const StyledJoinedUsersVariant1 = {
  Wrapper: styled.div`
    margin: 40px 0;
    padding: 12px 12px 16px 12px;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    background: linear-gradient(180deg, #574fcf 2.34%, #3734a6 100%);

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 1080px;
      padding: 40px;
      margin: 0 0 56px;
    }
  `,
  Column: styled.div`
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  `,
  QuantityWrapper: styled.div`
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 24px;
    border-radius: 24px;
    background: #221f80;
    margin-bottom: 16px;

    img {
      width: 78px;
      height: 94px;
    }
  `,
  Title: styled.h3`
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.WHITE};

    strong {
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      color: #ffd651;
    }
  `,
  BenefitsWrapper: styled.ul`
    margin-bottom: 24px;
    padding: 0 8px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: flex;
      flex-wrap: wrap;
      width: 617px;
      row-gap: 24px;
      justify-content: space-between;
      padding: 0;
      margin-bottom: 0;
      align-items: center;

      li:first-of-type {
        order: 1;
        width: 299px;
      }

      li:nth-of-type(3) {
        order: 2;
        width: 262px;
      }

      li:nth-of-type(2) {
        order: 3;
        width: 299px;
      }

      li:last-of-type {
        order: 4;
        width: 262px;
      }
    }
  `,
  BenefitsItem: styled.li`
    position: relative;
    margin-bottom: 16px;
    padding-left: 35px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: ${Color.WHITE};

    ::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 24px;
      height: 24px;
      background-image: ${({ theme }) => `url(${theme.images.checkboxIcon})`};
      background-size: 24px;
      background-repeat: no-repeat;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 0;
    }
  `,
  Button: styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: #35319f;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;

    img {
      width: 20px;
      height: 20px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-top: 24px;
      width: 327px;
      font-size: 20px;
      line-height: 26px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  `,
}
