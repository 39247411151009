import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import stayConnected from 'assets/images/stay-connected.webp'

import { StyledStayConnected as S } from './StayConnected.styles'

export const StayConnectedVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const handleContinue = useNextStep({
    pageId,
    pageName: '',
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <img src={stayConnected} alt="stay-connected" />
      </S.ImageContainer>
      <S.Column>
        <S.Text>{t`onboarding.stayConnected.title2`}</S.Text>
        <Button onClick={() => handleContinue('')}>Continue</Button>
      </S.Column>
    </S.Wrapper>
  )
}
