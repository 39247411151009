import React, { useRef } from 'react'

import { useDynamicPaywallConfig } from 'hooks/common/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/common/useGetPrices'
import { usePaywall } from 'hooks/common/usePaywall'

import { TPageProps } from 'models/common.model'

import { BenefitsVariant2 } from 'modules/subscriptions/components/benefits'
import {
  PLAN_ITEMS,
  SUBSCRIPTION_BLOCK_SCROLL_VIEW,
} from 'modules/subscriptions/constants'

import { Guarantee } from 'components/Guarantee'
import { Spinner } from 'components/Spinner'

import peopleImg from 'assets/images/subscriptions-people.webp'

import { SubscriptionTag } from 'root-constants/common'

import { SubheaderWithTimer } from '../../components/common/SubheaderWithTimer'
import { Timer } from '../../components/common/Timer'
import { StyledSubscriptions as S } from './SubscriptionsVariant3.styles'

export const SubscriptionsVariant3: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const subscriptionBlockRef = useRef<HTMLDivElement>(null)
  const imageContainerBlockRef = useRef<HTMLDivElement>(null)

  const { hasPrices, handleShowPayment } = usePaywall(nextPagePath)

  useGetPrices({ tags: SubscriptionTag.NO_TAX })
  const { subscriptionBlockType } = useDynamicPaywallConfig()

  const handleTimerButtonClick = () => {
    const subscriptionBlockBorder = subscriptionBlockRef.current?.offsetTop

    if (subscriptionBlockBorder) {
      document.body.scrollTo({
        top: subscriptionBlockBorder - SUBSCRIPTION_BLOCK_SCROLL_VIEW,
        behavior: 'smooth',
      })
    }
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      <SubheaderWithTimer
        elemForComparisonRef={imageContainerBlockRef}
        onClick={handleTimerButtonClick}
      />
      <S.Wrapper>
        <S.Column>
          <S.Title>Keep Your Family Safe</S.Title>
          <S.Subtitle>Keep calm and stay connected with your family</S.Subtitle>
          <Timer />
        </S.Column>

        <S.ImageContainer ref={imageContainerBlockRef}>
          <img src={peopleImg} alt="people" />
        </S.ImageContainer>

        <S.Column>
          <BenefitsVariant2 />
          <S.SubscriptionsTitle>Invest in Your Peace</S.SubscriptionsTitle>
          <S.PlanBlockWrapper ref={subscriptionBlockRef}>
            <S.SelectPlanBlock
              SelectPlanItem={PLAN_ITEMS[subscriptionBlockType]}
            />
          </S.PlanBlockWrapper>
          <S.Button onClick={handleShowPayment}>Start My Plan</S.Button>
          <S.SubscriptionDescription />
          <Guarantee />
          <S.Button onClick={handleShowPayment}>Start My Plan</S.Button>
          <S.Security />
        </S.Column>
      </S.Wrapper>
    </>
  )
}
