import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { useNextStep } from 'hooks/common/useNextStep'
import { useDelayedContinue } from 'hooks/ui/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { Language } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledDrivingHabits as S } from './DrivingHabits.styles'
import { OPTION_VALUES, PAGE_NAME } from './constants'

export const DrivingHabitsVariant1: React.FC<TPageProps> = ({ pageId }) => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.drivingHabits.question', { lng: Language.EN }),
    nextPagePath: `${PageId.CHILD_IN_TROUBLE_1}${search}`,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.drivingHabits.question`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.YES}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.drivingHabits.answers.yes`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.SOMETIMES}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.drivingHabits.answers.sometimes`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NO_WE_TALKED_ABOUT_IT}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.drivingHabits.answers.noVariant1`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NO_KID_DOESNT_DRIVE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.drivingHabits.answers.noVariant2`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
