import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ThemeProvider } from 'styled-components'

import {
  selectCurrentVariantCohortToUse,
  selectLanguage,
} from 'root-redux/selects/common'

import { Cohort, Language } from 'root-constants/common'

import {
  COMMON_THEME,
  DARK_THEME,
  FAMYLOCATE_THEME,
  SAFETY_THEME,
} from '../../styles/themes'

type TProps = {
  children: React.ReactNode
}

export const Theme: React.FC<TProps> = ({ children }) => {
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const language = useSelector(selectLanguage)

  const currentTheme = useMemo(() => {
    switch (cohortToUse) {
      case Cohort.SAFETY_4:
        return SAFETY_THEME
      case Cohort.FAMYLOCATE_V1:
      case Cohort.FAMYLOCATE_V2:
        return FAMYLOCATE_THEME
      case Cohort.GOOGLE_MAIN_10:
        return DARK_THEME
      default:
        return COMMON_THEME
    }
  }, [cohortToUse])

  return (
    <ThemeProvider
      theme={{
        ...currentTheme,
        ...(language === Language.ZH && {
          locales: {
            chineseTextFontWeight: 500,
          },
        }),
      }}
    >
      {children}
    </ThemeProvider>
  )
}
