import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useDynamicPaywallConfig } from 'hooks/common/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/common/useGetPrices'
import { usePaywall } from 'hooks/common/usePaywall'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useVatInfo } from 'hooks/common/useVatInfo'
import { useUserData } from 'hooks/user/useUserData'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { BenefitsVariant2 } from 'modules/subscriptions/components/benefits'
import { SubheaderWithTimer } from 'modules/subscriptions/components/common/SubheaderWithTimer'
import { SubscriptionDescriptionTrial } from 'modules/subscriptions/components/common/SubscriptionDescription'
import { BenefitsVariant1 } from 'modules/subscriptions/components/google/Benefits'
import { SAFETY_6_BENEFITS_DATA } from 'modules/subscriptions/components/google/Benefits/constants'
import { StepsVariant1 } from 'modules/subscriptions/components/google/Steps'
import {
  PLAN_ITEMS,
  SUBSCRIPTION_BLOCK_SCROLL_VIEW,
} from 'modules/subscriptions/constants'

import { Security } from 'components/Security'
import { Spinner } from 'components/Spinner'

import stepsLine from 'assets/images/average-line.png'
import kidImage from 'assets/images/subscriptions-kid-2.png'
import teenImage from 'assets/images/subscriptions-teen.png'

import { ChildFlow, SubscriptionTag } from 'root-constants/common'

import { StyledSubscriptionsVariant6 as S } from './SubscriptionsVariant6.styles'
import { SLIDER_DATA, STEPS_DATA } from './constants'

export const SubscriptionsVariant6: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const subscriptionBlockRef = useRef<HTMLDivElement>(null)
  const imageContainerBlockRef = useRef<HTMLDivElement>(null)

  const { subscriptions } = usePurchaseStore()
  const { hasPrices, handleShowPayment } = usePaywall(nextPagePath)
  const { subscriptionBlockType, subscriptionBlockTitle } =
    useDynamicPaywallConfig()
  const { childFlow } = useUserData()
  const hasIncludedVat = useVatInfo()

  useGetPrices({
    tags: hasIncludedVat ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX,
  })

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleTimerButtonClick = () => {
    const subscriptionBlockBorder = subscriptionBlockRef.current?.offsetTop

    if (subscriptionBlockBorder) {
      document.body.scrollTo({
        top: subscriptionBlockBorder - SUBSCRIPTION_BLOCK_SCROLL_VIEW,
        behavior: 'smooth',
      })
    }
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      <SubheaderWithTimer
        elemForComparisonRef={imageContainerBlockRef}
        onClick={handleTimerButtonClick}
      />
      <S.Wrapper>
        <S.Column>
          <S.Timer />
          <S.Title>{t`subscriptions.variant6.title`}</S.Title>
          <S.Subtitle>{t`subscriptions.variant6.subtitle`}</S.Subtitle>
        </S.Column>

        <S.ImageWrapper ref={imageContainerBlockRef}>
          <img
            src={childFlow === ChildFlow.KID ? kidImage : teenImage}
            alt="kid"
          />
        </S.ImageWrapper>

        <S.Column>
          <BenefitsVariant2 title={t`subscriptions.variant6.benefitsTitle`} />
          <S.SubscriptionsTitle>
            {subscriptionBlockTitle ||
              t`subscriptions.variant6.subscriptionsBlockTitle`}
          </S.SubscriptionsTitle>
          <S.PlanBlockWrapper ref={subscriptionBlockRef}>
            <S.SelectPlanBlock
              SelectPlanItem={PLAN_ITEMS[subscriptionBlockType]}
            />
          </S.PlanBlockWrapper>
          <SubscriptionDescriptionTrial />
          <S.Button onClick={handleShowPayment}>{t`actions.getPlan`}</S.Button>
        </S.Column>

        <BenefitsVariant1 benefits={SAFETY_6_BENEFITS_DATA} marginBottom={48} />

        <S.Column>
          <S.ReviewSlider reviews={SLIDER_DATA} />
          <S.Button onClick={handleShowPayment}>{t`actions.start`}</S.Button>
          <S.SubscriptionsTitle>
            {subscriptionBlockTitle ||
              t`subscriptions.variant5.subscriptionsTitle`}
          </S.SubscriptionsTitle>
          <S.SelectPlanBlock
            SelectPlanItem={PLAN_ITEMS[subscriptionBlockType]}
          />
          <SubscriptionDescriptionTrial />
          <S.Button onClick={handleShowPayment}>{t`actions.getPlan`}</S.Button>
          <S.HowItWorksTitle>{t`onboarding.introGoogle4.howItWorks`}</S.HowItWorksTitle>
          <StepsVariant1
            stepsData={STEPS_DATA}
            height={406}
            image={stepsLine}
            marginBottom={48}
          />
          <Security />
        </S.Column>
      </S.Wrapper>
    </>
  )
}
