import React, { useDeferredValue, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  GET_STATUS,
  SEND_USER_EMAIL,
  sendUserEmailAction,
} from 'root-redux/actions/user'
import {
  selectActionList,
  selectError,
  selectLanguage,
} from 'root-redux/selects/common'

import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'
import { useDynamicOBConfig } from 'hooks/common/useDynamicOBConfig'
import { useEmailInputField } from 'hooks/common/useEmailInputField'

import { eventLogger } from 'services/eventLogger.service'

import { TOP_EMAIL_DOMAINS } from 'modules/email/constants'
import { getFilteredEmailDomains } from 'modules/email/helpers'

import { Modal } from 'components/Modal'
import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'

import confidentialIcon from 'assets/images/confidential-icon.svg'
import secureIcon from 'assets/images/secure-icon.svg'
import star from 'assets/images/sprite/star.svg'

import { Language } from 'root-constants/common'

import { StyledEmailVariant3 as S } from './EmailVariant3.styles'

export const EmailVariant3: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const fetchingActionsList = useSelector(selectActionList)
  const error = useSelector(selectError)
  const language = useSelector(selectLanguage)

  const [areEmailTipsVisible, setAreEmailTipsVisible] = useState(false)
  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  const { title, subtitle, buttonText } = useDynamicOBConfig()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()
  const [email, , validateEmail] = useEmailInputField()
  const deferredEmail = useDeferredValue(email.value)

  const isStatusFetching =
    fetchingActionsList?.includes(SEND_USER_EMAIL) ||
    fetchingActionsList?.includes(GET_STATUS)

  const isButtonDisabled = !email.value || !email.isValid || isStatusFetching
  const filteredEmailDomains = getFilteredEmailDomains(deferredEmail)

  useEffect(() => {
    eventLogger.logEmailPageShown()
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!email.isValid || isStatusFetching) return

    dispatch(
      sendUserEmailAction({
        email: email.value,
        isPersonalDataAllowed,
        unsuccessCallback: () => setIsErrorModalShown(true),
      }),
    )
  }

  const handleChange = ({ target: { value } }) => {
    validateEmail(value)
    setAreEmailTipsVisible(true)
  }

  const handlePrefilledEmail = ({ currentTarget: { value } }) => {
    validateEmail(value)
    setAreEmailTipsVisible(false)
  }

  const handleTipsClick = ({ currentTarget: { value } }) => {
    const [userName] = email.value.split('@')

    validateEmail(`${userName}${value}`)
    setAreEmailTipsVisible(false)
  }

  return isStatusFetching ? (
    <Spinner />
  ) : (
    <>
      <S.Wrapper>
        <S.Content>
          <S.Disclaimer>
            <Trans
              i18nKey="onboarding.email.disclaimer"
              components={{ emoji: <SvgImage svg={star} width={16} /> }}
            />
          </S.Disclaimer>
          <form onSubmit={handleSubmit}>
            <S.Title>
              {title || <Trans i18nKey="onboarding.email.title3" />}
            </S.Title>
            <S.Subtitle>{subtitle || t`onboarding.email.subtitle7`}</S.Subtitle>

            <S.InputWrapper>
              <S.Input
                value={email.value}
                isValid={email.isValid}
                validationText={email.validationText}
                placeholder={t`onboarding.email.placeholder`}
                autoComplete="off"
                onChange={handleChange}
              />
              {areEmailTipsVisible && (
                <S.AutoCompleteWrapper>
                  {filteredEmailDomains.map((value) => (
                    <S.AutoCompleteButton
                      type="button"
                      key={value}
                      value={value}
                      onMouseDown={handlePrefilledEmail}
                    >
                      <S.AutoCompleteButtonTextWrapper>
                        {value}
                      </S.AutoCompleteButtonTextWrapper>
                    </S.AutoCompleteButton>
                  ))}
                </S.AutoCompleteWrapper>
              )}
            </S.InputWrapper>

            <S.TipsContainer>
              {TOP_EMAIL_DOMAINS.map((domain) => (
                <S.TipsButton
                  type="button"
                  key={domain}
                  value={domain}
                  disabled={!email.value}
                  onClick={handleTipsClick}
                >
                  {domain}
                </S.TipsButton>
              ))}
            </S.TipsContainer>

            <S.Button type="submit" disabled={isButtonDisabled}>
              {buttonText || t`actions.continue`}
            </S.Button>

            <S.SecurityInfoContainer isFrenchLang={language === Language.FR}>
              <S.SecurityInfo>
                <img src={confidentialIcon} alt="confidential" />
                <span>{t`onboarding.intro8.confidential`}</span>
              </S.SecurityInfo>
              <S.SecurityInfo hasSmallerFontSize={language === Language.PT}>
                <img src={secureIcon} alt="secure" />
                <span>{t`onboarding.intro8.secure`}</span>
              </S.SecurityInfo>
            </S.SecurityInfoContainer>
          </form>
        </S.Content>
      </S.Wrapper>
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
      >
        {error}
      </Modal>
    </>
  )
}
