import styled from 'styled-components'

import { SubscriptionDescription } from 'modules/subscriptions/components/common/SubscriptionDescription'

import { BurgerMenu } from 'components/BurgerMenu'
import { Button } from 'components/Button'

import checkboxViolet from 'assets/images/checkbox-violet.svg'
import bgImg from 'assets/images/subscriptions-bg.svg'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { BASE_COLUMN_WIDTH, MediaBreakpoint } from 'root-constants/common'

type TProps = {
  marginBottom?: number
}
export const StyledEmailSubscriptions = {
  Wrapper: styled.div<TProps>`
    ${basePageStyles};
    min-height: 100%;
    padding-top: 96px;
    overflow-x: hidden;
  `,
  Column: styled.div`
    ${baseColumnStyles};

    &[data-has-bg='true'] {
      position: relative;

      ::before {
        content: '';
        display: block;
        width: 100vw;
        aspect-ratio: 1/1;
        background-image: url(${bgImg});
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        z-index: -1;
      }
    }
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      right: 18px;
      top: 8px;
    }
  `,
  PartnerImageContainer: styled.div`
    width: 100%;
    margin: 39px 0 37px;
    aspect-ratio: 374/288;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${BASE_COLUMN_WIDTH}px;
    }
  `,
  KidImageContainer: styled.div`
    width: 100%;
    margin: 39px 0 37px;
    aspect-ratio: 375/322;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${BASE_COLUMN_WIDTH}px;
    }
  `,
  Title: styled.h1`
    font-size: 32px;
    line-height: 44px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 12px;
  `,
  Text: styled.span`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-bottom: 28px;

    strong {
      font-weight: 600;
    }
  `,
  Subtitle: styled.h2<TProps>`
    ${baseTitleStyles};
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
  `,
  List: styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 45px;
  `,
  ContentItem: styled.li`
    position: relative;
    margin-bottom: 20px;
    padding-left: 35px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;

    ::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 23px;
      height: 23px;
      background-image: url(${checkboxViolet});
      background-size: 23px;
    }
  `,
  Button: styled(Button)<TProps>`
    height: 56px;
    line-height: 56px;
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
  `,
  SubscriptionDescription: styled(SubscriptionDescription)<TProps>`
    margin: 0 auto 32px;
  `,
  PlanBlockWrapper: styled.div`
    width: 100%;
  `,
}
