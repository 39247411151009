import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { FixedContainer } from 'components/FixedContainer'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'

import { CheckboxCustomValue, Language } from 'root-constants/common'

import { StyledPartnersDataVariant2 as S } from './PartnersDataVariant2.styles'
import { OPTION_VALUES, PAGE_NAME } from './constants'

export const PartnersDataVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])
  const allOptionValues = Object.values(OPTION_VALUES)

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.partnersData.question', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked) {
        value === CheckboxCustomValue.ALL_OF_THE_ABOVE
          ? setAnswers(allOptionValues)
          : setAnswers([...answers, value])
        return
      }

      value === CheckboxCustomValue.ALL_OF_THE_ABOVE
        ? setAnswers([])
        : setAnswers(answers.filter((item) => item !== value))
    },
  }

  return (
    <S.Wrapper>
      <S.Content>
        <S.Column>
          <S.Title>{t`onboarding.partnersData.question`}</S.Title>
          <StyledOption.OptionsContainer paddingBottom={4}>
            <Option
              {...optionProps}
              value={OPTION_VALUES.MOVEMENT_HISTORY}
              checked={answers.includes(OPTION_VALUES.MOVEMENT_HISTORY)}
            >
              <S.QuestionButton hasCheckboxIcon>
                {t`onboarding.partnersData.answers.movementHistory`}
              </S.QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={OPTION_VALUES.LOCATION_NOTIFICATIONS}
              checked={answers.includes(OPTION_VALUES.LOCATION_NOTIFICATIONS)}
            >
              <S.QuestionButton hasCheckboxIcon>
                {t`onboarding.partnersData.answers.locationNotifications`}
              </S.QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={OPTION_VALUES.BATTERY_ALERTS}
              checked={answers.includes(OPTION_VALUES.BATTERY_ALERTS)}
            >
              <S.QuestionButton hasCheckboxIcon>
                {t`onboarding.partnersData.answers.batteryAlerts`}
              </S.QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={OPTION_VALUES.SOS_NOTIFICATIONS}
              checked={answers.includes(OPTION_VALUES.SOS_NOTIFICATIONS)}
            >
              <S.QuestionButton hasCheckboxIcon>
                {t`onboarding.partnersData.answers.sosNotifications`}
              </S.QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={OPTION_VALUES.DRIVING_MODE}
              checked={answers.includes(OPTION_VALUES.DRIVING_MODE)}
            >
              <S.QuestionButton hasCheckboxIcon>
                {t`onboarding.partnersData.answers.drivingMode`}
              </S.QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={CheckboxCustomValue.ALL_OF_THE_ABOVE}
              checked={answers.length === allOptionValues.length}
            >
              <S.QuestionButton hasCheckboxIcon>
                {t`commonComponents.allOfTheAbove`}
              </S.QuestionButton>
            </Option>
          </StyledOption.OptionsContainer>
          <FixedContainer>
            <Button
              onClick={() => handleContinue(answers)}
              disabled={!answers.length}
            >
              {t`actions.next`}
            </Button>
          </FixedContainer>
        </S.Column>
      </S.Content>
    </S.Wrapper>
  )
}
