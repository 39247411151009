import styled from 'styled-components'

import { MediaBreakpoint } from 'root-constants/common'

type TTextProps = {
  textAlign?: string
}
export const StyledBenefitsVariant3 = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 48px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      gap: 48px;
    }
  `,
  Title: styled.h2`
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    text-align: center;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 40px;
      line-height: 55px;
    }
  `,
  ContentWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      flex-direction: row;
      gap: 48px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: end;

      div:nth-child(-n + 3) {
        min-height: 229px;
      }

      div:nth-last-of-type(-n + 2) h3 {
        margin-bottom: 24px;
      }
    }
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 328px;
      justify-content: space-between;
    }
  `,
  Subtitle: styled.h3<TTextProps>`
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    text-align: ${({ textAlign }) => textAlign || 'center'};
    width: 100%;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 0;
    }
  `,
  Image: styled.img`
    aspect-ratio: 311/100;
    width: 100%;
    max-width: 311px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      aspect-ratio: 328/106;
      max-width: 328px;
    }
  `,
}
