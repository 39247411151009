import React, { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { SupportLink } from 'components/SupportLink'

import { CDN_FOLDER_LINK, Language } from 'root-constants/common'

import { StyledSecurity as S } from './Security.styles'

export const Security: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...props
}) => {
  const language = useSelector(selectLanguage)

  const { t } = useTranslation()

  const securityImageLink = `${CDN_FOLDER_LINK}/images/secure_${language}.webp`

  const hasOverSizeFont = language === Language.FR

  return (
    <S.SecureCard {...props}>
      <S.SecureImage src={securityImageLink} height="60" alt="secure" />
      <ul>
        <li>
          <S.SecureItemTitle>
            {t`subscriptions.security.informationTitle`}
          </S.SecureItemTitle>
          <S.SecureItemText hasOverSizeFont={hasOverSizeFont}>
            {t`subscriptions.security.informationText`}
          </S.SecureItemText>
        </li>
        <li>
          <S.SecureItemTitle>{t`subscriptions.security.secureTitle`}</S.SecureItemTitle>
          <S.SecureItemText hasOverSizeFont={hasOverSizeFont}>
            {t`subscriptions.security.secureText`}
          </S.SecureItemText>
        </li>
        <li>
          <S.SecureItemTitle>{t`subscriptions.security.helpTitle`}</S.SecureItemTitle>
          <S.SecureItemText hasOverSizeFont={hasOverSizeFont}>
            <SupportLink />
          </S.SecureItemText>
        </li>
      </ul>
    </S.SecureCard>
  )
}
