import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/ui/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { goTo } from 'browser-history'
import {
  FREQUENCY_OPTION_DATA,
  Frequency,
  Language,
} from 'root-constants/common'

import { StyledFeltHelpless as S } from './FeltHelpless.styles'
import { PAGE_NAME } from './constants'

export const FeltHelplessVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = (answer) => {
    dispatch(
      setAnswersAction({
        answers: answer,
        pageId,
      }),
    )

    dispatch(sendUserAnswersAction())

    eventLogger.logQuestion({
      question: t('onboarding.feltHelpless.question', { lng: Language.EN }),
      answers: answer,
      pageName: PAGE_NAME,
    })

    answer === Frequency.NEVER || answer === Frequency.RARELY
      ? goTo(alternativePagePath)
      : goTo(nextPagePath)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column2>
        <S.Title>{t`onboarding.feltHelpless.question`}</S.Title>
        <StyledOption.OptionsContainer>
          {FREQUENCY_OPTION_DATA.map(({ value, text }) => (
            <Option
              {...optionProps}
              key={value}
              value={value}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t(text)}</QuestionButton>
            </Option>
          ))}
        </StyledOption.OptionsContainer>
      </S.Column2>
    </S.Wrapper>
  )
}
