import React, { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useVatInfo } from 'hooks/common/useVatInfo'

import { SupportLink } from 'components/SupportLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import {
  CURRENCY_SYMBOLS,
  INTRO_OFFER_FULL_PERIOD_NAMES,
  TRIAL_QUANTITY_TO_MARKUP_MAP,
} from 'root-constants/common'

import { StyledSubscriptionDescription } from './SubscriptionDescription.styles'

type TSubscriptionDescriptionProps = HTMLAttributes<HTMLParagraphElement>

export const SubscriptionDescriptionTrial: React.FC<
  TSubscriptionDescriptionProps
> = ({ ...props }) => {
  const { t } = useTranslation()

  const { trialPrice, trialPeriodDays, currentPrice, currency } =
    usePurchaseStore()
  const hasIncludedVAT = useVatInfo()

  return (
    <StyledSubscriptionDescription {...props}>
      {t('subscriptions.description.text2', {
        price: trialPrice,
        minimumFractionDigits: 2,
        currencySymbol: CURRENCY_SYMBOLS[currency],
        period: t(TRIAL_QUANTITY_TO_MARKUP_MAP[trialPeriodDays]),
        periodName: t(INTRO_OFFER_FULL_PERIOD_NAMES[trialPeriodDays]),
        vatInfo: hasIncludedVAT ? t('commonComponents.inclVat') : '',
        currentPrice: currentPrice.toFixed(2),
        currency: currency.toUpperCase(),
      })}{' '}
      <TermsOfUseLink /> {t`subscriptions.description.contact`} <SupportLink />
    </StyledSubscriptionDescription>
  )
}
