import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { DesktopProvider } from 'components/DesktopProvider'
import { FixedContainer } from 'components/FixedContainer'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import feelingsImage from 'assets/images/feelings.jpg'

import { CheckboxCustomValue, Language } from 'root-constants/common'

import { StyledFeelings as S } from './Feelings.styles'
import { OPTION_VALUES, PAGE_NAME } from './constants'

export const FeelingsVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])
  const allOptionValues = Object.values(OPTION_VALUES)

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.feelings.question', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked) {
        value === CheckboxCustomValue.ALL_OF_THE_ABOVE
          ? setAnswers(allOptionValues)
          : setAnswers([...answers, value])
        return
      }

      value === CheckboxCustomValue.ALL_OF_THE_ABOVE
        ? setAnswers([])
        : setAnswers(answers.filter((item) => item !== value))
    },
  }

  return (
    <DesktopProvider imageSrc={feelingsImage}>
      <S.Wrapper>
        <S.Column>
          <S.Title>{t`onboarding.feelings.question`}</S.Title>
          <StyledOption.OptionsContainer paddingBottom={4}>
            <Option
              {...optionProps}
              value={OPTION_VALUES.STRESSED}
              checked={answers.includes(OPTION_VALUES.STRESSED)}
            >
              <QuestionButton hasCheckboxIcon>
                {t`onboarding.feelings.answers.stressed`}
              </QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={OPTION_VALUES.NOT_ABLE_TO_CARE}
              checked={answers.includes(OPTION_VALUES.NOT_ABLE_TO_CARE)}
            >
              <QuestionButton hasCheckboxIcon>
                {t`onboarding.feelings.answers.notAbleToCare`}
              </QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={OPTION_VALUES.CLOSE}
              checked={answers.includes(OPTION_VALUES.CLOSE)}
            >
              <QuestionButton hasCheckboxIcon>
                {t`onboarding.feelings.answers.close`}
              </QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={CheckboxCustomValue.ALL_OF_THE_ABOVE}
              checked={answers.length === allOptionValues.length}
            >
              <QuestionButton hasCheckboxIcon>
                {t`commonComponents.allOfTheAbove`}
              </QuestionButton>
            </Option>
          </StyledOption.OptionsContainer>
          <FixedContainer>
            <Button
              onClick={() => handleContinue(answers)}
              disabled={!answers.length}
            >
              {t`actions.next`}
            </Button>
          </FixedContainer>
        </S.Column>
      </S.Wrapper>
    </DesktopProvider>
  )
}
