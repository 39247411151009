import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentRequest } from '@stripe/stripe-js/dist/stripe-js/payment-request'
import { usePageInfo } from 'providers/PageInfoProvider'

import { resetErrorAction } from 'root-redux/actions/common'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { eventLogger } from 'services/eventLogger.service'

import { PaymentMethod } from 'modules/payment/constants'
import {
  purchaseAction,
  setIsPaymentFlowsShownAction,
  setPaymentMethodAction,
} from 'modules/payment/redux/actions/common'

import {
  CENTS_IN_DOLLAR,
  PERIOD_NAME_TO_MARKUP_MAP,
  TrialPeriod,
} from 'root-constants/common'

import { StyledLinkPaymentButton as S } from './LinkPaymentButton.styles'

export const LinkPaymentButton: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()

  const {
    trialPeriodDays,
    trialPrice,
    currentPrice,
    currency,
    periodName,
    periodQuantity,
  } = usePurchaseStore()

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  )

  const { currentPageId } = usePageInfo()

  const planPeriodDescription =
    !!trialPeriodDays && trialPeriodDays <= TrialPeriod.ONE_WEEK
      ? t('payment.applePayShortTrial', { count: trialPeriodDays })
      : t('payment.applePay', {
          periodQuantity,
          periodName: t(PERIOD_NAME_TO_MARKUP_MAP[periodName], {
            count: periodQuantity,
          }),
        })

  const calculatedPrice = +(
    (trialPrice || currentPrice) * CENTS_IN_DOLLAR
  ).toFixed()

  useEffect(() => {
    if (!stripe || !elements) {
      return
    }

    const pr = stripe.paymentRequest({
      currency,
      country: 'GB',
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: planPeriodDescription,
        amount: calculatedPrice,
      },
      disableWallets: ['applePay', 'googlePay'],
    })

    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr)
      }
      dispatch(setIsPaymentFlowsShownAction(true))
    })

    pr.on('paymentmethod', (event) => {
      dispatch(resetErrorAction())
      dispatch(
        purchaseAction({
          stripe,
          paymentPageId: currentPageId,
          createPaymentResFromDigitalWallet: event,
        }),
      )
    })
  }, [
    calculatedPrice,
    currency,
    currentPageId,
    dispatch,
    elements,
    planPeriodDescription,
    stripe,
  ])

  const handleClick = () => {
    if (!paymentRequest) return

    paymentRequest?.show()
    dispatch(setPaymentMethodAction(PaymentMethod.LINK))
    eventLogger.logPaymentMethodSelected({ paymentMethod: PaymentMethod.LINK })
  }

  return paymentRequest ? (
    <S.Wrapper>
      <S.Divider />
      <S.Button type="button" onClick={handleClick} />
    </S.Wrapper>
  ) : null
}
