import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'
import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { SvgImage } from 'components/SvgImage'

import burgerMenu from 'assets/images/burger-menu-v3.svg'
import arrow from 'assets/images/sprite/right-arrow.svg'

import { Language } from 'root-constants/common'

import { StyledIntroVariant9 as S } from './IntroVariant9.styles'

export const IntroVariant9: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isEUUser, cookieConsent } = useCookieConsentAnswer()
  const language = useSelector(selectLanguage)

  const isCookieConsentAvailable =
    !cookieConsent && isEUUser && language === Language.EN

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.BurgerMenu menuIconSrc={burgerMenu} />
      <S.Column>
        <S.Title isCookieConsentAvailable={isCookieConsentAvailable}>
          {t`onboarding.intro9.title`}
        </S.Title>
        <S.Subtitle>{t`onboarding.intro9.subtitle`}</S.Subtitle>
        <S.Button onClick={() => handleContinue('')}>
          {t`actions.continue`}
          <SvgImage svg={arrow} width={24} />
        </S.Button>
      </S.Column>
    </S.Wrapper>
  )
}
