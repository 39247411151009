import styled from 'styled-components'

import phoneImgDesktop from 'assets/images/search-number-phone-desktop.png'

import { Color, MediaBreakpoint } from 'root-constants/common'

export const StyledSearchNumberAnimationVariant3 = {
  Wrapper: styled.div`
    display: none;
    justify-content: center;
    gap: 50px;
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin-bottom: 24px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: flex;
      max-width: 543px;
    }
  `,
  BenefitsColumn: styled.div`
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 177px;
    width: 100%;
    position: relative;
    left: 24px;
  `,
  Benefits: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      width: 20px;
      height: 20px;
    }
  `,
  Text: styled.p`
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${Color.WHITE};
  `,
  AnimationContainer: styled.div`
    width: 100%;
    min-height: 531px;
    background-image: url(${phoneImgDesktop});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  `,
}
