import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'
import { useUserData } from 'hooks/user/useUserData'

import { TPageProps } from 'models/common.model'

import { DesktopProvider } from 'components/DesktopProvider'

import femaleImgLaptop from 'assets/images/proof-3-female-laptop.jpg'
import femaleImg from 'assets/images/proof-3-female.webp'
import maleImgLaptop from 'assets/images/proof-3-male-laptop.jpg'
import maleImg from 'assets/images/proof-3-male.webp'

import { StyledSocialProof as S } from './SocialProof.styles'
import { PAGE_NAME_3_FEMALE, PAGE_NAME_3_MALE } from './constants'

export const SocialProofVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isFemale } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    pageName: isFemale ? PAGE_NAME_3_FEMALE : PAGE_NAME_3_MALE,
    question: '',
    nextPagePath,
  })

  return (
    <DesktopProvider imageSrc={isFemale ? maleImgLaptop : femaleImgLaptop}>
      <S.Wrapper>
        <S.Image
          src={isFemale ? maleImg : femaleImg}
          alt="trying to take off the ring"
        />
        <S.Column>
          <S.Title laptopMarginBottom={47}>
            {t`onboarding.socialProof.variant3.title`}
          </S.Title>
          <S.Text marginBottom={14} laptopMarginBottom={47}>
            <Trans i18nKey="onboarding.socialProof.variant3.paragraph1" />
          </S.Text>
          <S.Text marginBottom={14} laptopMarginBottom={32}>
            <Trans i18nKey="onboarding.socialProof.variant3.paragraph2" />
          </S.Text>
          <S.Note marginBottom={6}>{t`onboarding.socialProof.note`}</S.Note>
          <S.Button onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </S.Button>
        </S.Column>
      </S.Wrapper>
    </DesktopProvider>
  )
}
