import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import globeImg from 'assets/images/geolocation-on-globe.svg'
import gpsIcon from 'assets/images/gps-icon-blue.png'

import { StyledJoinedUsersVariant1 as S } from './JoinedUsersVariant1.styles'
import { BENEFITS_DATA } from './constants'

type TProps = {
  handleScroll: () => void
}

export const JoinedUsersVariant1: React.FC<TProps> = ({ handleScroll }) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Column>
        <S.QuantityWrapper>
          <img src={globeImg} alt="globe" />
          <S.Title>
            <Trans i18nKey="onboarding.introGoogle5.usersJoined.title" />
          </S.Title>
        </S.QuantityWrapper>
        <S.BenefitsWrapper>
          {BENEFITS_DATA.map((item) => (
            <S.BenefitsItem key={item}>{t(item)}</S.BenefitsItem>
          ))}
        </S.BenefitsWrapper>
      </S.Column>
      <S.Button onClick={handleScroll}>
        <img src={gpsIcon} alt="icon" />
        {t`onboarding.introGoogle5.locateNumber`}
      </S.Button>
    </S.Wrapper>
  )
}
