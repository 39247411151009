import styled from 'styled-components'

import checkboxViolet from 'assets/images/checkbox-violet.svg'

import { MediaBreakpoint } from 'root-constants/common'

export const StyledBenefitsVariant2 = {
  Wrapper: styled.div`
    margin: 32px 0 38px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin: 100px 0;

      ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 48px;
      }
    }
  `,
  Title: styled.h2`
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
    margin-bottom: 24px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 40px;
      line-height: 55px;
      max-width: 902px;
      margin: 0 auto 48px;
    }
  `,
  Text: styled.li`
    position: relative;
    padding-left: 35px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-left: 10px;
    margin-bottom: 16px;
    text-align: start;

    ::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 23px;
      height: 23px;
      background-image: url(${checkboxViolet});
      background-size: 23px;
      background-position: top center;
    }

    &:last-of-type p {
      margin-bottom: 0;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 516px;
      width: 100%;
      font-size: 18px;
      line-height: 25px;
      padding-left: 50px;
      margin-bottom: 0;

      ::before {
        width: 32px;
        height: 32px;
        background-size: 32px;
        background-repeat: no-repeat;
      }
    }
  `,
}
