import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import {
  CURRENCY_SYMBOLS,
  INTRO_OFFER_PERIODS,
  INTRO_OFFER_PERIOD_NAMES,
  PERIOD_NAME_TO_MARKUP_MAP,
} from 'root-constants/common'

import { StyledVatTotalAmount as S } from './VatTotalAmount.styles'

export const VatTotalAmount: React.FC = () => {
  const { t } = useTranslation()

  const {
    trialPriceId,
    trialPrice,
    trialPeriodDays,
    trialPriceTax,
    fullPriceTax,
    currentPrice,
    periodName,
    periodQuantity,
    currency,
  } = usePurchaseStore()

  const { textPath, periodNamePath, period, price, tax } = useMemo(() => {
    if (trialPriceId) {
      return {
        textPath: 'payment.totalVatTrial',
        periodNamePath: INTRO_OFFER_PERIOD_NAMES[trialPeriodDays],
        period: INTRO_OFFER_PERIODS[trialPeriodDays],
        price: trialPrice,
        tax: trialPriceTax,
      }
    }

    return {
      textPath: 'payment.totalVat',
      periodNamePath: PERIOD_NAME_TO_MARKUP_MAP[periodName],
      period: periodQuantity,
      price: currentPrice,
      tax: fullPriceTax,
    }
  }, [
    currentPrice,
    fullPriceTax,
    periodName,
    periodQuantity,
    trialPeriodDays,
    trialPrice,
    trialPriceId,
    trialPriceTax,
  ])

  return (
    <S.Wrapper>
      <Trans
        i18nKey={textPath}
        values={{
          periodQuantity: period,
          periodName: t(periodNamePath, {
            count: period,
          }),
          currencySymbol: CURRENCY_SYMBOLS[currency],
          price,
          minimumFractionDigits: 2,
          tax,
        }}
      />
    </S.Wrapper>
  )
}
