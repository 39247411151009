import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useTheme } from 'styled-components'

import {
  selectAnswers,
  selectCurrentVariantCohortToUse,
} from 'root-redux/selects/common'

import { useIsFamylocateCohort } from 'hooks/common/useIsFamylocateCohort'
import { useGetUserPosition } from 'hooks/user/useGetUserPosition'
import { useUserData } from 'hooks/user/useUserData'

import { formatPhoneNumber } from 'helpers/phone'

import locationIcon from 'assets/images/location-violet-icon.svg'
import locationYellowIcon from 'assets/images/location-yellow.icon.svg'

import { StyledFoundUser as S } from './FoundUser.styles'

type TProps = {
  isFinished: boolean
  handleContinue: (answer: string) => void
}

export const FoundUser: React.FC<TProps> = ({ isFinished, handleContinue }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const answers = useSelector(selectAnswers)
  const cohort = useSelector(selectCurrentVariantCohortToUse)

  const { userPhoneNumber } = useUserData()
  const { timeZone } = useGetUserPosition()

  const formattedPhoneNumber = formatPhoneNumber(userPhoneNumber)

  const isFamyLocateCohort = useIsFamylocateCohort(cohort)

  return (
    <S.Wrapper isFinished={isFinished}>
      <S.LocationIcon
        src={isFamyLocateCohort ? locationYellowIcon : locationIcon}
        alt="icon"
      />
      <S.PhoneNumberWrapper>
        <S.Title>{t`onboarding.email.numberLocated`}</S.Title>
        <S.PhoneNumber>
          {t(`onboarding.locationSearch.phoneNumber`, {
            phoneNumber: formattedPhoneNumber,
          })}
        </S.PhoneNumber>
      </S.PhoneNumberWrapper>
      <S.LocationDataWrapper>
        <S.LocationData>
          <S.Text>{t`onboarding.locationSearch.country`}</S.Text>
          <S.Text>{answers?.userPhoneCountry}</S.Text>
        </S.LocationData>
        <S.LocationData>
          <S.Text>{t`onboarding.locationSearch.timezone`}</S.Text>
          <S.Text>
            {t(`onboarding.locationSearch.gmt`, {
              timeZone,
            })}
          </S.Text>
        </S.LocationData>
        <S.LocationData>
          <S.Text>{t`onboarding.locationSearch.city`}</S.Text>
          <S.Text isBlurred>Chicago</S.Text>
        </S.LocationData>
        <S.LocationData>
          <S.Text>{t`onboarding.locationSearch.location`}</S.Text>
          <img src={theme.images.checkIcon} alt="icon" />
          <S.Defined>{t`onboarding.locationSearch.defined`}</S.Defined>
        </S.LocationData>
      </S.LocationDataWrapper>
      <S.Button type="button" onClick={() => handleContinue('')}>
        {t`actions.continue`}
      </S.Button>
    </S.Wrapper>
  )
}
