import styled, { css } from 'styled-components'

import { MediaBreakpoint } from 'root-constants/common'

type TProps = {
  isMenuVisible: boolean
  isArabic?: boolean
}

type TButtonProps = {
  menuIconSrc: string
}

const baseButtonStyles = css`
  position: absolute;
  width: 40px;
  height: 30px;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  background-position: center;
  background-size: 24px;
  background-repeat: no-repeat;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
    height: 40px;
  }

  @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
    width: 52px;
    height: 48px;
    background-size: 32px;
  }
`

export const StyledBurgerMenu = {
  Wrapper: styled.div``,
  Burger: styled.button<TButtonProps>`
    ${baseButtonStyles};
    inset-inline-end: 0;
    top: 0;
    background-image: url(${({ menuIconSrc }) => menuIconSrc});
    z-index: 1;
  `,
  Backdrop: styled.div<TProps>`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    z-index: ${({ isMenuVisible }) => (isMenuVisible ? '3' : '-1')};
    opacity: ${({ isMenuVisible }) => (isMenuVisible ? '1' : '0')};
    visibility: ${({ isMenuVisible }) => (isMenuVisible ? 'visible' : 'none')};
    pointer-events: ${({ isMenuVisible }) =>
      isMenuVisible ? 'default' : 'none'};
    transition: opacity 0.25s;
  `,
  Menu: styled.nav<TProps>`
    position: fixed;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    width: 300px;
    height: var(--full-height, 100vh);
    background: ${({ theme }) => theme.colors.burgerBackground};
    padding: 88px 40px;
    transition: transform 0.25s;
    transform: ${({ isMenuVisible, isArabic }) => {
      if (isArabic) {
        return isMenuVisible ? 'translateX(0)' : 'translateX(-100%)'
      }
      return isMenuVisible ? 'translateX(0)' : 'translateX(100%)'
    }};
    z-index: 4;

    a {
      display: inline-block;
      font-weight: ${({ theme }) =>
        theme.locales?.chineseTextFontWeight ||
        theme.common.burgerLinksFontWeight};
      font-size: ${({ theme }) => theme.common.burgerLinksFontSize};
      line-height: ${({ theme }) => theme.common.burgerLinksLineHeight};
      color: ${({ theme }) => theme.colors.burgerLinksColor};
      margin-bottom: 32px;
    }
  `,
  MenuCloseButton: styled.button`
    ${baseButtonStyles};
    top: 4px;
    inset-inline-start: 4px;
    background-image: url(${({ theme }) => theme.images.closeBurgerMenuButton});
  `,
}
